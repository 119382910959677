import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom'
import '../../../Css/pages.css';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Alert, Box, Button, Checkbox, FormControlLabel, Radio, RadioGroup, Snackbar, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import Select from "react-select";
import dayjs from 'dayjs';
import Loader from '../../../Components/Loader';
import Header from '../../../Header/Header';
import LeftNav from '../../LeftNav/LeftNav';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '../../Vessel/AddVessel/AddVessel.css';
import getCompany from '../../../Pages/Company';
import getCountry from '../../../Pages/Country';
import axios from 'axios';
import env from '../../../config';
import AddBoxIcon from '@mui/icons-material/AddBox';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);


const AddVessel = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Determine where to navigate back to
    const goback = location.state?.from?.pathname || "/";
    const from = location.state?.from?.pathname || "/signin"

    // State variables
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [lname, setLName] = useState('');
    const vesselData = location.state?.vesselData || {};
    const isupdate = location.state?.isupdate || false;
    const [shipName, setShipName] = useState(vesselData?.vesselName || '');
    const [classid, setClassID] = useState(vesselData?.classId || '');
    const [status, setStatus] = useState(vesselData?.classId || '');
    const [vesselType, setVesselType] = useState(vesselData?.vesselType || '');
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companyOptions, setCompanyOptions] = useState([]);

    const [imoNumber, setImoNumber] = useState(vesselData?.imoNumber || '');
    const [flag, setFlag] = useState(vesselData?.flag || '');
    const [countries, setCountries] = useState([]);
    const [callSign, setCallSign] = useState(vesselData?.distinctiveNoOfLetters || '');
    const [portOfRegistry, setPortOfRegistry] = useState(vesselData?.portOfRegistry || '');
    const [grossTonnage, setGrossTonnage] = useState(vesselData?.grossTonnage || '');
    const [dimensions, setDimensions] = useState(vesselData?.lengthBreadthWidth || '');
    const [constructionDate, setConstructionDate] = useState(vesselData?.dateOfConstruction ? dayjs.utc(vesselData.dateOfConstruction) : null);
    const [keelLaidDate, setKeelLaidDate] = useState(vesselData?.keelLaidDate ? dayjs.utc(vesselData.keelLaidDate) : null);


    const [remarks, setRemarks] = useState(vesselData?.remarks || '');

    const [showMessage, setShowMessage] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    // Navigate back to the previous page
    const handleCancel = () => {
        navigate('/vessel', {
            state: {
                search: location.state?.search
            }
        });
    };
    const [originalConstructionDate, setOriginalConstructionDate] = useState(constructionDate);
    const [originalKeelLaidDate, setOriginalKeelLaidDate] = useState(keelLaidDate);

    useEffect(() => {
        // Update the original dates when the component mounts
        setOriginalConstructionDate(constructionDate);
        setOriginalKeelLaidDate(keelLaidDate);
    }, [constructionDate, keelLaidDate]);

    const [isLoading, setIsLoading] = useState(false);

    // Fetch companies and countries when component mounts
    useEffect(() => {
        fetchCompanies();
        // handleToken();
        fetchCountries();
    }, []);

    // Set initial selected company when companies are fetched
    useEffect(() => {
        if (companies.length > 0 && companyOptions.length > 0) {
            const initialCompany = companies.find(company => company.companyId === vesselData?.companyId);
            const initialCompanyOption = companyOptions.find(option => option.value === initialCompany?.companyId);
            setSelectedCompany(initialCompanyOption || null);
            console.log("ssbn", initialCompanyOption);
        }


    }, [companies]);

    // Fetch country data
    const fetchCountries = async () => {
        try {
            const response = await getCountry("");
            console.log(response);
            setCountries(response);
        } catch (error) {
            console.log("Error:", error);
        }
    };

    // Sort and format countries for select options
    const sortedCountries = [...countries].sort((a, b) => {
        const labelA = a.label || '';
        const labelB = b.label || '';
        return labelA.localeCompare(labelB);
    });
    const countryOptions = sortedCountries.map((country) => ({
        value: country.countryName,
        label: country.countryName,
    }));

    // Handle country selection
    const handleCountrySelect = (selectedOption) => {
        setFlag(selectedOption.value);
    };

    // Fetch company data
    const fetchCompanies = async () => {
        try {
            const response = await getCompany("");
            const sortedCompanies = response
                .filter(company => company.companyName !== null)
                .sort((a, b) => {
                    const labelA = a.companyName || '';
                    const labelB = b.companyName || '';
                    return labelA.localeCompare(labelB);
                });

            setCompanies(sortedCompanies);


            const options = sortedCompanies.map((company) => ({
                value: company.companyId,
                label: company.companyName,
            }));
            setCompanyOptions(options);

        } catch (error) {
            console.log("Error fetching companies:", error);
        }
    };

    // Handle company selection
    const handleCompanySelect = (selectedOption) => {
        console.log("Selected company:", selectedOption);
        setSelectedCompany(selectedOption);
    };
    const [errors, setErrors] = useState({});

    // Validate form inputs
    const validate = () => {
        const newErrors = {};

        if (!shipName) newErrors.shipName = " Name of Vessel is required.";
        if (!imoNumber) newErrors.imoNumber = "IMO No is required.";
        if (!selectedCompany) newErrors.selectedCompany = "Client Name is required.";
        if (!vesselType) newErrors.vesselType = "Vessel Type is required.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleAddVesselSubmit = async (event) => {
        event.preventDefault();
        console.log("ahhva", selectedCompany)

        if (!validate()) {
            return;
        }
        const vesselId = new Number(selectedCompany.value.companyId);

        const newVesselObject = {
            id: vesselId,
            companyId: selectedCompany.value,
            companyName: selectedCompany.label,
            vesselName: shipName,
            imoNumber: imoNumber,
            vesselType: vesselType,
            flag: flag,
            distinctiveNoOfLetters: callSign,
            portOfRegistry: portOfRegistry,
            grossTonnage: grossTonnage,
            lengthBreadthWidth: dimensions,
            dateOfConstruction: convertToNextDayISO(constructionDate),
            keelLaidDate: convertToNextDayISO(keelLaidDate),
            remarks: remarks,
            createdBy: localStorage.getItem('userFullName'),
            createdOn: new Date(),
            updatedBy: localStorage.getItem('userFullName'),
            updatedOn: new Date(),
            classId: classid,
            status: "Saved",
        };
        setLoading(true);
        try {
            let response;
            if (isupdate) {
                const updatedConstructionDate = constructionDate.isSame(originalConstructionDate) ? constructionDate : convertToNextDayISO(constructionDate);
                const updatedKeelLaidDate = keelLaidDate.isSame(originalKeelLaidDate) ? keelLaidDate : convertToNextDayISO(keelLaidDate);
                response = await axios.put(
                    `${env.backend}/api/vessel/updateVessel`, {
                    id: vesselData.id,
                    companyId: selectedCompany.value,
                    companyName: selectedCompany.label,
                    vesselName: shipName,
                    imoNumber: imoNumber,
                    vesselType: vesselType,
                    flag: flag,
                    distinctiveNoOfLetters: callSign,
                    portOfRegistry: portOfRegistry,
                    grossTonnage: grossTonnage,
                    lengthBreadthWidth: dimensions,
                    dateOfConstruction: updatedConstructionDate,
                    keelLaidDate: updatedKeelLaidDate,
                    remarks: remarks,

                    updatedBy: localStorage.getItem('userFullName'),
                    updatedOn: new Date(),
                    classId: classid,
                    status: "Saved",
                }

                );
            } else {
                response = await axios.post(
                    env.backend + "/api/vessel",
                    newVesselObject
                );
            }

            if (response.status === 201 || response.status === 200) {
                console.log("respo", response);
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                    navigate('/vessel');
                }, 1000);
            } else {
                console.error("Error creating/updating Vessel");
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }
    const [hideAddCountry, setHideAddCountry] = useState(false);
    const [newCountry, setNewCountry] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    const country = countries.map((country) => ({
        value: country,
        label: country.countryName,
    }));
    const handleAddCountry = () => {
        setHideAddCountry(true);
    };
    const closeAddCountry = () => {
        setHideAddCountry(false);
    }
    const handleAddCountrySubmit = async () => {
        // Find the maximum ID from existing countries and increment it for the new country
        const maxId = country.reduce((max, country) => {
            return country.value.id > max ? country.value.id : max;
        }, 0);
        const newId = maxId + 1;

        const newCountryObject = {
            companyId: newId,
            countryName: newCountry,

        };

        console.log(newCountryObject);

        try {
            // Make an API call to the backend to create a new country
            const response = await axios.post(
                env.backend + "/api/country",
                newCountryObject
            );

            if (response.status === 201) {
                // User created successfully
                console.log("Country created!");
                // Additional actions if needed
            } else {
                // Error occurred while creating the user
                console.error("Error creating Country");
                // Additional error handling if needed
            }
        } catch (error) {
            console.error("Error:", error);
            // Additional error handling if needed
        }

        // Add the new company to the state
        //setCountry([...country, newCountryObject]);

        fetchCountries();
        setNewCountry("");
        // setShowAddCountry(false);
        // setHideAddCountry(true);
        closeAddCountry();
    };
    const handleFileUpload = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const convertToNextDayISO = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        return date.toISOString();
    };
    const handleShowSnackbar = () => {
        setMessage(isupdate ? 'Vessel updated successfully!!!' : 'New Vessel created successfully!!!');
        setOpen(true);
    };
    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                setOpen(false);
            }, 3000); // Close after 3 seconds

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [open]);

    // Call handleShowSnackbar when appropriate (e.g., after vessel creation/update)
    useEffect(() => {
        if (showMessage && !loading) {
            handleShowSnackbar();
        }
    }, [showMessage, loading]);

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid container-fluid-ex">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div className="card-listing-section">
                                    <div className="right-wrapper right-wrapper-ex">
                                        <div>
                                            <h1 className="titlereg ph-view">{isupdate ? 'Update Vessel' : 'Create Vessel'}</h1>
                                            <div className="form-container">
                                                <form className="formregister" onSubmit={handleAddVesselSubmit}>
                                                    <div className="grid-container">
                                                        <label>
                                                            <span className="vessel-label">Vessel Name</span>
                                                            <div className="input-container">
                                                                <span className="required-star">*</span>
                                                                <input
                                                                    id="shipName"
                                                                    placeholder="Name of Vessel"
                                                                    type="text"
                                                                    className="inputreg"
                                                                    autoComplete="off"
                                                                    onChange={(e) => setShipName(e.target.value)}
                                                                    value={shipName}
                                                                    required
                                                                />
                                                            </div>
                                                        </label>

                                                        <label>
                                                            <span className="vessel-label">Class ID</span>
                                                            <input
                                                                id='classid'
                                                                placeholder="Class ID"
                                                                type="text"
                                                                className="inputreg"
                                                                autoComplete='off'
                                                                onChange={(e) => { setClassID(e.target.value) }}
                                                                value={classid}
                                                                required
                                                            />
                                                        </label>

                                                        <label>
                                                            <span className="vessel-label">Vessel Type</span>
                                                            <div className="input-container">
                                                                <span className="required-star">*</span>
                                                                <input
                                                                    id='Type of Vessel'
                                                                    placeholder="Type of Vessel"
                                                                    type="text"
                                                                    className="inputreg"
                                                                    autoComplete='off'
                                                                    onChange={(e) => { setVesselType(e.target.value) }}
                                                                    value={vesselType}
                                                                    required
                                                                />
                                                            </div>
                                                        </label>
                                                        <FormControl fullWidth >
                                                            <span className="vessel-label">Client Name</span>
                                                            <span className="required-star">*</span>
                                                            <Select
                                                                className="select-template"
                                                                placeholder="Client"
                                                                options={companyOptions}
                                                                value={selectedCompany}
                                                                onChange={handleCompanySelect}
                                                                required
                                                                style={{ width: "100%" }}
                                                                styles={{
                                                                    container: (provided) => ({
                                                                        ...provided,
                                                                        flexGrow: 1,
                                                                        width: '100%',
                                                                    }),
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        width: '100%',
                                                                    }),
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        maxHeight: 160,
                                                                        overflowY: 'auto',
                                                                    }),
                                                                    menuList: (provided) => ({
                                                                        ...provided,
                                                                        maxHeight: 160,
                                                                    }),
                                                                }}
                                                            />
                                                        </FormControl>
                                                        <label>
                                                            <span className="vessel-label">IMO Number</span>
                                                            <div className="input-container">
                                                                <span className="required-star">*</span>
                                                                <input
                                                                    id='number'
                                                                    placeholder="IMO No"
                                                                    type="text"
                                                                    className="inputreg"
                                                                    autoComplete='off'
                                                                    onChange={(e) => { setImoNumber(e.target.value) }}
                                                                    value={imoNumber}
                                                                    required
                                                                />
                                                            </div>
                                                        </label>
                                                        <FormControl fullWidth>
                                                            <span className="vessel-label">Select Flag</span>
                                                            <div style={{ display: "flex" }}>
                                                                <Select
                                                                    placeholder="Select Flag"
                                                                    options={countryOptions}
                                                                    className="select-template"
                                                                    value={countryOptions.find(option => option.value === flag)}
                                                                    onChange={handleCountrySelect}
                                                                    required
                                                                    style={{ width: "100%" }}
                                                                    styles={{
                                                                        container: (provided) => ({
                                                                            ...provided,
                                                                            flexGrow: 1,
                                                                            width: '100%',
                                                                        }),
                                                                        control: (provided) => ({
                                                                            ...provided,
                                                                            width: '100%',
                                                                        }),
                                                                        menu: (provided) => ({
                                                                            ...provided,
                                                                            maxHeight: 160,
                                                                            overflowY: 'auto',
                                                                        }),
                                                                        menuList: (provided) => ({
                                                                            ...provided,
                                                                            maxHeight: 160,
                                                                        }),
                                                                    }}
                                                                />
                                                                <div className="button-container">
                                                                    <AddBoxIcon sx={{ color: "green", width: '60px', height: '35px', marginTop: "-1px", marginRight: "-5px" }} onClick={handleAddCountry} />
                                                                </div>
                                                                {hideAddCountry && (
                                                                    <div id="id02" className="submodal">
                                                                        <div className="modal-content animate">
                                                                            <div className="submodal-container">
                                                                                <input
                                                                                    type="text"
                                                                                    value={newCountry}
                                                                                    onChange={(e) => setNewCountry(e.target.value)}
                                                                                    placeholder="Country Name"
                                                                                />
                                                                                <div>
                                                                                    <input className="modal-choose-btn" type="file" onChange={handleFileUpload} />
                                                                                </div>
                                                                                <div className="btn-container-multiple">
                                                                                    <button className="modal-close-btn" onClick={closeAddCountry}>Close</button>
                                                                                    <button className="modal-submit-btn" onClick={handleAddCountrySubmit}>Save</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </FormControl>

                                                        <label>
                                                            <span className="vessel-label">Call Sign</span>
                                                            <input
                                                                id='callSign'
                                                                placeholder="Distinctive Number of Letters/Call Sign"
                                                                type="text"
                                                                className="inputreg"
                                                                autoComplete='off'
                                                                onChange={(e) => { setCallSign(e.target.value) }}
                                                                value={callSign}
                                                                required
                                                            />
                                                        </label>


                                                        <label>
                                                            <span className="vessel-label">Port of Registry</span>
                                                            <input
                                                                id='portOfRegistry'
                                                                placeholder="Port of Registry"
                                                                type="text"
                                                                className="inputreg"
                                                                autoComplete='off'
                                                                onChange={(e) => { setPortOfRegistry(e.target.value) }}
                                                                value={portOfRegistry}
                                                                required
                                                            />
                                                        </label>

                                                        <label>
                                                            <span className="vessel-label">Gross Tonnage/Net Tonnage</span>
                                                            <input
                                                                id='grossTonnage'
                                                                placeholder="Gross Tonnage/Net Tonnage"
                                                                type="text"
                                                                className="inputreg"
                                                                autoComplete='off'
                                                                onChange={(e) => { setGrossTonnage(e.target.value) }}
                                                                value={grossTonnage}
                                                                required
                                                            />
                                                        </label>

                                                        <label>
                                                            <span className="vessel-label">Length/Breadth/Width</span>
                                                            <input
                                                                id='dimensions'
                                                                placeholder="Length/Breadth/Width"
                                                                type="text"
                                                                className="inputreg"
                                                                autoComplete='off'
                                                                onChange={(e) => { setDimensions(e.target.value) }}
                                                                value={dimensions}
                                                                required
                                                            />
                                                        </label>


                                                    </div>



                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', flexDirection: 'column', gap: 2 }}>

                                                            <DatePicker
                                                                sx={{
                                                                    '.MuiOutlinedInput-root': {
                                                                        borderRadius: '10px',
                                                                    },
                                                                    '.MuiInputBase-input': {
                                                                        fontFamily: 'Segoe UI Historic',
                                                                    },
                                                                    '.MuiFormLabel-root': {
                                                                        fontFamily: 'Segoe UI Historic',
                                                                        marginTop: '-6px',
                                                                        '&.MuiInputLabel-shrink': {
                                                                            marginTop: '0px',
                                                                        },
                                                                    },
                                                                }}
                                                                label="Date of Construction"
                                                                value={constructionDate}
                                                                onChange={(newValue) => setConstructionDate(newValue)}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                inputFormat="DD/MMM/YYYY"
                                                                format="DD/MMM/YYYY"
                                                            />
                                                            <DatePicker
                                                                sx={{
                                                                    '.MuiOutlinedInput-root': {
                                                                        borderRadius: '10px',
                                                                    },
                                                                    '.MuiInputBase-input': {
                                                                        fontFamily: 'Segoe UI Historic',
                                                                    },
                                                                    '.MuiFormLabel-root': {
                                                                        fontFamily: 'Segoe UI Historic',
                                                                        marginTop: '-6px',
                                                                        '&.MuiInputLabel-shrink': {
                                                                            marginTop: '0px',
                                                                        },
                                                                    },
                                                                }}
                                                                label="Keel Laid Date"
                                                                value={keelLaidDate}
                                                                onChange={(newValue) => setKeelLaidDate(newValue)}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                inputFormat="DD/MMM/YYYY"
                                                                format="DD/MMM/YYYY"
                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                    <label>
                                                        <span className="vessel-label">Remarks</span>
                                                        <input
                                                            id='remarks'
                                                            placeholder="Remarks"
                                                            type="text"
                                                            className="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => { setRemarks(e.target.value) }}
                                                            value={remarks}
                                                            required
                                                        />
                                                    </label>
                                                </form>
                                            </div>

                                        </div>
                                        {loading && <Loader />}
                                        {showMessage && !loading && (
                                            <div>
                                                <Snackbar
                                                    open={open}
                                                    autoHideDuration={3000}
                                                    onClose={() => setOpen(false)} // You can keep this if you want to ensure proper closing
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                                >
                                                    <Alert severity="success" sx={{ width: '100%', color: 'white', background: 'green', }}>
                                                        {message}
                                                    </Alert>
                                                </Snackbar>
                                            </div>

                                        )}
                                        <div className="btn-container-multiple">
                                            <button className="modal-submit-btn" type="submit" onClick={handleAddVesselSubmit}><b>{isupdate ? 'Update' : 'Save'}</b></button>
                                            <button style={{ marginRight: "23px" }} className="modal-close-btn" onClick={handleCancel}><b>Close</b></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}

export default AddVessel
