import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../../Header/Header";
import LeftNav from "../../Components/LeftNav/LeftNav";
import { FormControl, Checkbox, ListItemText, Select, InputLabel, OutlinedInput, Grid, Typography, Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, MenuItem, TextField } from '@mui/material';
import { Delete as DeleteIcon, Visibility as VisibilityIcon, AddToPhotos as AddToPhotosIcon } from '@mui/icons-material';
import { useDropzone } from "react-dropzone";
import ENV from "../../config";
import LoaderDashboard from '../../Components/LoaderDashboard';
import './Attachments.css';
import SaveIcon from '@mui/icons-material/Save';

function Attachments() {
    const [attachments, setAttachments] = useState([]);
    const [viewingImage, setViewingImage] = useState(null);
    const [savedFiles, setSavedFiles] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);

    const [flagDocumentOptions] = useState([
        "Applications",
        "Authorizations",
        "Agreements",
        "Supporting Documents",
        "Audit Reports"
    ]);
    const [companyDocumentOptions] = useState([
        "Certificate of Incorporation",
        "Tax Returns",
        "Articles",
        "Audit Reports",
        "Financial Report",
        "Customer Details"
    ]);
    const [selectedDocumentOptions, setSelectedDocumentOptions] = useState([]);

    const onDrop = (acceptedFiles) => {
        const newFiles = acceptedFiles.map((file) => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve({
                        name: file.name,
                        content: e.target.result,
                    });
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(newFiles).then((files) => {
            setAttachments((prevAttachments) => [...prevAttachments, ...files]);
            setIsSaveDisabled(false);
            
        });
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

    const handleDelete = (file) => {
        setAttachments((prevAttachments) => prevAttachments.filter((attachment) => attachment !== file));
        setIsSaveDisabled(false);
    };

    const handleView = (file) => {
        setViewingImage(file.content);
    };

    const handleClose = () => {
        setViewingImage(null);
    };

    const handleSave = () => {
        if (!isSaveDisabled) {
            setSavedFiles(attachments);
            setIsSaveDisabled(true);
            
        }
        console.log('file:', savedFiles);
    };

    useEffect(() => {
        if (attachments.length === 0) {
            setIsSaveDisabled(true);
        }
    }, [attachments]);

    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const surveyMap = new Map();
    surveys
        .sort((b, a) => (a.createdOn && b.createdOn) ? a.createdOn.localeCompare(b.createdOn) : 0)
        .forEach((survey) => {
            const { companyName, vesselName, ...rest } = survey;
            if (surveyMap.has(companyName)) {
                surveyMap.get(companyName).push({ companyName, vesselName, ...rest });
            } else {
                surveyMap.set(companyName, [{ companyName, vesselName, ...rest }]);
            }
        });

    const navigate = useNavigate();
    const location = useLocation();
    const goback = location.state?.from?.pathname || "/";
    const createsurvey = location.state?.from?.pathname || "/create-survey";

    useEffect(() => {
        fetchSurveys();
    }, []);

    useEffect(() => {
        filterSurveys();
    }, [surveys]);

    const fetchSurveys = async () => {
        try {
            const response = await axios.get(ENV.backend + '/api/survey');
            setSurveys(Object.values(response.data));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching surveys:', error);
            setLoading(false);
        }
    };

    const [selectedCompanies, setSelectedCompanies] = useState([]);

    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedVessels, setSelectedVessels] = useState([]);
    const [filteredSurveys, setFilteredSurveys] = useState([]);

    const companyNames = Array.from(surveyMap.keys());
    const countryNames = Array.from(new Set(surveys.map(survey => survey.countryName)));

    const vesselNames = [...new Set(surveys.map(survey => survey.vesselName))];

    useEffect(() => {
        filterSurveys();
    }, [selectedCompanies, selectedCountries, selectedVessels]);

    const handleCompanyChange = (event) => {
        setSelectedCompanies(event.target.value);
    };

    const handleDocumentTypeChange = (event) => {
        setDocumentType(event.target.value);
        setSelectedDocumentOptions([]);
    };

    const handleDocumentOptionsChange = (event) => {
        setSelectedDocumentOptions(event.target.value);
    };



    const handleCountryChange = (event) => {
        setSelectedCountries(event.target.value);
    };

    const handleVesselChange = (event) => {
        setSelectedVessels(event.target.value);
    };



    const filterSurveys = () => {
        let surveys = [];

        if (selectedCompanies.length > 0) {
            selectedCompanies.forEach(company => {
                if (surveyMap.has(company)) {
                    surveys.push(...surveyMap.get(company));
                }
            });
        } else {
            surveyMap.forEach((value) => {
                surveys.push(...value);
            });
        }
        if (documentType === 'flag') {
            surveys = surveys.filter(survey =>
                selectedDocumentOptions.includes(survey.flagDocumentType)
            );
        } else if (documentType === 'company') {
            surveys = surveys.filter(survey =>
                selectedDocumentOptions.includes(survey.companyDocumentType)
            );
        }

        if (selectedCountries.length > 0) {
            surveys = surveys.filter(survey =>
                selectedCountries.includes(survey.countryName)
            );
        }

        if (selectedVessels.length > 0) {
            surveys = surveys.filter(survey =>
                selectedVessels.includes(survey.vesselName)
            );
        }
        if (selectedDocumentOptions.length > 0) {
            surveys = surveys.filter(survey =>
                survey.surveyCertificateList.some(cert =>
                    selectedDocumentOptions.includes(cert.documentType)
                )
            );
        }

        setFilteredSurveys(surveys);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list">
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div class="path" style={{ marginLeft: "15px" }}>
                                <h2 class="inactive">
                                    Surveyor Dashboard
                                </h2>
                                <h2>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="6"
                                        height="12"
                                        viewBox="0 0 6 12"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 11L4 6L1 1"
                                            stroke="#8B8B8B"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </h2>
                                <h2 class="inactive">
                                    Templates
                                </h2>
                                <h2>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="6"
                                        height="12"
                                        viewBox="0 0 6 12"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 11L4 6L1 1"
                                            stroke="#8B8B8B"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </h2>
                                <h2 class="inactive" >
                                    Editor
                                </h2>
                                <h2>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="6"
                                        height="12"
                                        viewBox="0 0 6 12"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 11L4 6L1 1"
                                            stroke="#8B8B8B"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </h2>
                                <h2 class="inactive" >
                                    Checklist
                                </h2>
                                <h2>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="6"
                                        height="12"
                                        viewBox="0 0 6 12"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 11L4 6L1 1"
                                            stroke="#8B8B8B"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </h2>
                                <h2 class="active" >
                                    Attachement
                                </h2>

                            </div>
                            <div className="container-fluid-buyer">
                                <div className="card-listing-section">
                                    <div className="right-wrapper">
                                        <div>
                                            <h1>
                                                <div className="header-surveyor" style={{ textAlign: 'left', marginLeft: '0px', marginTop: '-14px' }}>
                                                    Attachments
                                                </div>
                                            </h1>
                                        </div>
                                        <div style={{ marginTop: '-15px',display: 'flex' ,gap: '2%'}}>
                                            <Grid container spacing={2} style={{ display: 'contents' }}>
                                                                                                    
                                                <Grid item>
                                                    <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
                                                        <InputLabel>Document Type</InputLabel>
                                                        <Select
                                                            value={documentType}
                                                            onChange={handleDocumentTypeChange}
                                                            input={<OutlinedInput label="Document Type" />}
                                                        >
                                                            <MenuItem value="flag">Flag Related Documents</MenuItem>
                                                            <MenuItem value="company">Company Legal Documents</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {documentType && (
                                                    <Grid item>
                                                        <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
                                                            <InputLabel>{documentType === 'flag' ? 'Flag Documents' : documentType === 'company' ? 'Company Documents' : 'Document Options'}</InputLabel>
                                                            <Select
                                                                multiple
                                                                value={selectedDocumentOptions}
                                                                onChange={handleDocumentOptionsChange}
                                                                input={<OutlinedInput label={documentType === 'flag' ? 'Flag Documents' : documentType === 'company' ? 'Company Documents' : 'Document Options'} />}
                                                                renderValue={(selected) => selected.join(', ')}
                                                            >
                                                                {documentType === 'flag' && flagDocumentOptions.map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        <Checkbox checked={selectedDocumentOptions.indexOf(option) > -1} />
                                                                        <ListItemText primary={option} />
                                                                    </MenuItem>
                                                                ))}
                                                                {documentType === 'company' && companyDocumentOptions.map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        <Checkbox checked={selectedDocumentOptions.indexOf(option) > -1} />
                                                                        <ListItemText primary={option} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                )}


                                            </Grid>

                                        </div>
                                        <Box p={3}>
                                            <Box {...getRootProps()} p={2} border="1px dashed grey" textAlign="center" mb={2}>
                                                <input {...getInputProps()} />
                                                <Typography variant="body1">Drag and drop some files here, or click to select files</Typography>
                                            </Box>
                                            <Box>
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Remarks"
                                                    multiline
                                                    rows={2}
                                                    style={{ width: "   34rem", }}
                                                    placeholder="Type here..."
                                                />
                                                {attachments.map((file, index) => (
                                                    <Box key={index} display="flex" alignItems="center" mb={2}>
                                                        <Typography variant="body1" mr={2}>{file.name}</Typography>
                                                        <Button style={{ width: "20%", }} onClick={() => handleView(file)} color="primary">
                                                            <VisibilityIcon /><Typography >View</Typography>
                                                        </Button>
                                                        <Button aria-label="delete" style={{ width: "20%", color: "red" }} onClick={() => handleDelete(file)} color="secondary">
                                                            <DeleteIcon /> <Typography >Delete</Typography>
                                                        </Button>
                                                        

                                                    </Box>
                                                ))}
                                            </Box>
                                            {attachments.length > 0 && (
                                                <Button style={{ width: "10%", marginLeft: "70rem", }} variant="contained"
                                                    
                                                    
                                                    startIcon={<SaveIcon />}
                                                    onClick={handleSave}
                                                >
                                                    Save
                                                </Button>
                                            )}
                                            <Dialog open={!!viewingImage} onClose={handleClose}>
                                                <DialogTitle>View</DialogTitle>
                                                <DialogContent>
                                                    <Box component="img" src={viewingImage} alt="Viewing Attachment" width="100%" />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose} color="primary">Close</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Box>
                                        {
                                            loading ? (<LoaderDashboard />) : (
                                                <Paper>
                                                    
                                                </Paper>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
}

export default Attachments;
