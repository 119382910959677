import {createContext, useState , useContext} from 'react';

const AuthContext = createContext({});
export function useAuth(){
    return useContext(AuthContext);
}
export const AuthProvider = (props) => {
    const [auth, setAuth] = useState({});

    return (
        <AuthContext.Provider value = {{auth, setAuth}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext;