

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom'
//import './Css/pages.css';
import './ResetPassword.css';

import LeftNav from '../LeftNav/LeftNav';
import Header from '../../Header/Header';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import env from '../../config';
import Snackbars from '../Snackbar';
import Loader from '../Loader';



const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [type, setType] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);


    // Function to validate password format
    const validatePassword = (pwd) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,}$/;
        return regex.test(pwd);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    // Function to handle password reset
    const handleResetPassword = () => {
        if (!validatePassword(password)) {
            setMessage(
                'Password must contain at least 8 characters including at least one uppercase letter, one lowercase letter, one number, and one special character.'
            );
            setType("error");
            setOpenSnackbar(true);
            return;
        }

        if (password !== confirmPassword) {
            setMessage('Passwords do not match.');
            setType("error");
            setOpenSnackbar(true);

            return;
        }

        // Logic for handling password reset
        // For example, you might send a request to the backend here

        // setMessage('Password reset link has been sent to your email.');
        setLoading(true)
        try {
            fetch(env.identityApi + '/api/identity/ResetPassword', {
                method: 'POST',
                headers: { 'content-Type': 'application/json' },
                body: JSON.stringify({
                    email: email, password: password, confirmpassword: password
                })
            })
                .then(async res => {
                    setLoading(false);

                     if (!res.ok) {
                        // Handle non-2xx status codes
                        setMessage('An error occurred');
                        setType("error");
                        console.log("de");
                        
                        throw new Error('An error occurred');
                    }
            
                    return res.json(); // Parse the response if status code is 2xx
                })
                .then(res => {
                    if (res?.succeeded === true) {
                        setMessage('Passwords reset successfully.');
                        setType("success");
                        setOpenSnackbar(true);
                    }
                    else if (res?.succeeded === false) {
                        setMessage(res?.errors[0]);
                        setType("error");
                    }
                })
                .catch(err => {
                    setLoading(false);
                    setMessage(err.message || 'Something went wrong');
                    setType("error");
                });
        }
        catch (err) {
            setLoading(false);
            setMessage(err.message || 'An unexpected error occurred');
            setType("error");
        }
    };
    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">

                                    <div class="right-wrapper" style={{ minHeight: '53vh' }}>
                                        <div >
                                            <h1 class="titlereg">Reset Password</h1>
                                            {(loading ? (<Loader />) : <div>
                                                <form class="formregister">
                                                    <div className="reset-password">
                                                        <label>
                                                            <input
                                                                type="email"
                                                                placeholder="Username"
                                                                value={email} className="inputreg"
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                        </label>
                                                        <label>
                                                            <input
                                                                type="password"
                                                                placeholder="New Password"
                                                                value={password} className="inputreg"
                                                                onChange={(e) => setPassword(e.target.value)}
                                                            />
                                                        </label>
                                                        <label>
                                                            <input
                                                                type="password"
                                                                placeholder="Confirm New Password"
                                                                value={confirmPassword} className="inputreg"
                                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>

                                                </form>
                                            </div>)}

                                        </div>

                                        {/* {message && <q>{message}</q>} */}
                                        <div class="btn-container-multiple">
                                            <button style={{ marginRight: "25px" }} class="modal-submit-btn" onClick={handleResetPassword}>Reset Password</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
            <Snackbars
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                type={type}
                message={message}
            />
        </div>


    )
}

export default ResetPassword;
