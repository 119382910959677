import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'

const ViewPage = () => {
    // Define the data directly in the component
    const data = {
      name: 'John Doe',
      assignee: 'Jane Smith',
      designation: 'Software Engineer',
      company: 'Tech Solutions',
      department: 'Development',
      email: 'john.doe@example.com',
      phoneNumber: '123-456-7890',
    };
  
    // Destructure the data object
    const { name, assignee, designation, company, department, email, phoneNumber } = data;
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/department');
    };
  return (
      <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>

          <div className="header-container">
              <h1><div className="heading-survey" >View Department</div></h1>
          </div>

      <Grid container spacing={2}>
       
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Name</Typography>
          <Typography variant="body5" class="table-grid-new-more">{name}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Role</Typography>
          <Typography variant="body5" class="table-grid-new-more">{assignee}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Actions</Typography>
          <Typography variant="body5" class="table-grid-new-more">{designation}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">testname</Typography>
          <Typography variant="body5" class="table-grid-new-more">{company}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Department</Typography>
          <Typography variant="body5" class="table-grid-new-more">{department}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Email</Typography>
          <Typography variant="body5" class="table-grid-new-more">{email}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Phone Number</Typography>
          <Typography variant="body5" class="table-grid-new-more">{phoneNumber}</Typography>
              </Grid>             
          </Grid>
          <div class="btn-container-multiple" style={{ marginRight: "20px" }}>

              <button style={{ marginRight: "25px" }} className="modal-close-btn" onClick={handleCancel}><b>Close</b></button>
          </div>
    </Paper>
  );
};

export default ViewPage;
