import axios from 'axios';
import ENV from "../config";
const getCompany =  async(param1) => {
  try {
    const response = await axios.get(ENV.backend+ '/api/company', {

     // params: {
       
       // companyName: param1
      //},
    });

    if (response.status === 200) {
      console.log(response.data)
      return response.data;
    } else {
      throw new Error('Failed to retrieve company objects');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getCompany;
