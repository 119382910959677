import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'
const ViewReqPage = () => {
    // Define the data directly in the component
    const data = {
      id: 1, name: 'John Doe', vessel: 'Vessel A', vesselType: 'Type 1', surveyType: 'Survey X', CreatedOn: '2024-01-15', CreatedBy: 'Admin A'
    };
  
    // Destructure the data object
    const { id,name, vessel,vesselType, surveyType, CreatedOn, CreatedBy } = data;
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/requestlist');
    };
  return (
      <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
          <div className="header-container">
              <h1><div className="heading-survey" >View Item</div></h1>
          </div>
      <Grid container spacing={2}>
              
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Id</Typography>
          <Typography variant="body5" class="table-grid-new-more">{id}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Name</Typography>
          <Typography variant="body5" class="table-grid-new-more">{name}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Vessel</Typography>
          <Typography variant="body5" class="table-grid-new-more">{vessel}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Vessel Type</Typography>
          <Typography variant="body5" class="table-grid-new-more">{vesselType}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Survey Type</Typography>
          <Typography variant="body5" class="table-grid-new-more">{surveyType}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Created On</Typography>
          <Typography variant="body5" class="table-grid-new-more">{CreatedOn}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Created By</Typography>
          <Typography variant="body5" class="table-grid-new-more">{CreatedBy}</Typography>
        </Grid>
          </Grid>
          <div class="btn-container-multiple" style={{ marginRight: "20px" }}>

              <button style={{ marginRight: "25px" }} className="modal-close-btn" onClick={handleCancel}><b>Close</b></button>
          </div>
    </Paper>
  );
};

export default ViewReqPage;
