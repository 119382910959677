import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div className='App'>
      <h1>Home</h1>
      <p>Something went wrong with what you did last time !!! Please click below to go back to Home page and Try again!</p><br/>
      <Link to="/">NEO CMS Admin Home</Link><br/>
      
    </div>
  )
}

export default ErrorPage
