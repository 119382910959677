import React from 'react';
import { Grid, Typography, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LeftNav from '../../LeftNav/LeftNav';
import Header from '../../../Header/Header';

const ViewCompany = () => {
    // Define the data directly in the component
    const location = useLocation();
    const data = location.state?.data

    // Destructure the data object
    const { companyName, companyId, email, phoneNumber, address, locationName,
        website,
        categoryName,
        codeName,
        createdBy,
        createdOn,
        updatedBy,
        updatedOn,
    } = data;
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/company', {
            state: {
                search: location.state?.search
            }
        });

    };
    return (

        <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
            <div className="header-container">
                <h1><div className="heading-survey" >View Client</div></h1>
            </div>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Name</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{data.companyName}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Address</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{data.address}</Typography>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Location</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{data.locationName}</Typography>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Email</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{data.email}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Phone Number</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{data.phoneNumber}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Website</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{data.website}</Typography>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Category Name</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{data.categoryName}</Typography>
                </Grid> */}

                {/* <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Code Name</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{data.codeName}</Typography>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Created By</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{data.createdBy}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Created On</Typography>
                    <Typography variant="body5" class="table-grid-new-more">
                        {data.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(data.createdOn)) : ''}
                    </Typography>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Updated By</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{data.updatedBy}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Updated On</Typography>
                    <Typography variant="body5" class="table-grid-new-more">
                        {data.updatedOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(data.updatedOn)) : ''}
                    </Typography>
                </Grid> */}

            </Grid>
            <div class="btn-container-multiple" style={{ marginRight: "20px" }}>

                <button style={{ marginRight: "25px" }} className="modal-close-btn" onClick={handleCancel}><b>Close</b></button>
            </div>
        </Paper>
    );
};


export default ViewCompany;
