import React from 'react';
import { css } from "@emotion/react";
// import variables from '../../themes.module.scss';

const Loader = () => {

    const override = css`
        display: block;
        margin: 0 auto;        
        `;

    return(
        <div
            style={{
            height: "100%",
            background: "#a5a4a44f",   
            // zIndex: 9999,
            zIndex: 99999999999999999999,
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            position: "fixed",
            width: "100%",
            top: 0,
            left: 0
            // top: "0"
            }}
            >
            <div
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 999999999999999
            
            }}
            >
                <div className="loader-text">Please wait...</div>
            </div> 
            </div>
    )
}

export default Loader;