import * as React from 'react';
import ENV from "../../config";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
//import { DecoupledEditor } from '@ckeditor/ckeditor5-build-decoupled-document';
//import LineHeight from 'ckeditor5-line-height';
import '../../Css/customckeditor.css';
//import '../../Css/doceditor.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import CircularLoader from '../../Components/CircularLoader';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LeftNav from '../../Components/LeftNav/LeftNav';
import Header from '../../Header/Header';
import handleToken from '../../Components/TokenHandler';



const { version, useReducer } = React;
/* Liz Added */
const { useEffect, useState } = React;

const USER_SERVICE_URL = ENV.backend + '/api/html-string';

const CONVERTER_SERVICE_URL = ENV.backend + '/api/html-to-pdf';
const styles = "<style> @page {size: A4;margin: 0.2cm 1cm 0.5cm 1cm;@bottom-center {content:'Page ' counter(page) ' of ' counter(pages);font-size: 8pt; margin-top:0;}}.todo-list__label__description {position: relative;top: -2px;} ::marker{font-size: xx-small;}  body p span {margin-bottom: 0px !important;margin-top: 0px !important; line-height: 1.25;}body p {margin-bottom: 0px !important;margin-top: 0px !important;line-height: 1; font-size:12px;} body p img { max-width: 100px; max-height: 200px;} body figure img { max-width: 100px; max-height: 200px;} body figure.image.image-style-side {display: flex;align-items: flex-end;flex-direction: column;} body figure.image {display: flex;align-items: center;flex-direction: column;}  .todo-list {list-style: none; margin:0;}.todo-list .todo-list__label > input {margin-top:8px;}.table .ck-table-resized { table-layout: fixed; } .table table { overflow: hidden; } .table td, .table th { overflow-wrap: break-word; position: relative; padding: 0 !important; font-size: 12px; font-family: 'Times New Roman', Times, serif;} .table > figcaption { display: table-caption; caption-side: top; word-break: break-word; text-align: center; padding: .6em; font-size: .75em; outline-offset: -1px; } .table { margin: 0 auto; display: table; } .table table { border-collapse: collapse; border-spacing: 0; width: 100%; height: 100%; } .table table td, .table table th { min-width: 2em; padding: 0 !important; height:15px; padding-left: 2px !important;} .table table th { font-weight: bold; } .table th { text-align: right; } .table th { text-align: left; }.table table tr {page-break-inside: avoid;  page-break-before: auto; page-break-after: auto;}.table tr {page-break-inside: avoid;  page-break-before: auto; page-break-after: auto;}</style>"
//const top = "<?xml version=\"1.0\" encoding=\"utf-8\"?><!DOCTYPE html PUBLIC \"-//W3C//DTD XHTML 1.1//EN\" \"http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd\"><html xmlns=\"http://www.w3.org/1999/xhtml\"><head><meta http-equiv=\"Content-Type\" content=\"application/xhtml+xml; charset=utf-8\" /><title>THE GOVERNMENT OF BELIZE</title><link href=\"UserDefinedFileName.css\" type=\"text/css\" rel=\"stylesheet\"/></head><body>";
const top = '<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><meta http-equiv="Content-Type" content="application/xhtml+xml; charset=utf-8" /><title>NEOCAP TEMPLATES</title></head>'
const body = "<body>"
const bottom = "</body></html>"


/**
 * `App` component manages state of underlying `CKEditor` and `Sidebar` components.
 *
 * Custom `CKEditor` component memoizes certain props and it will ignore any new values. For instance, this is true for `config` and `type`.
 * In order to force new `config` or `type` values, use keyed component.
 * This way `CKEditor` component is re-mounted and new instance of editor is created.
 */
function CertEditorNew() {
	// State variables for managing editor data, loading states, and display messages
	const [loading, setLoading] = React.useState(false);
	const [userdata, setUserData] = useState({ users: "", isFetching: false });
	const [certdata, setCertData] = useState('');
	const [editor1, setEditor] = useState();
	const [showMessage, setShowMessage] = useState(false);

	// Extract data from location state
	const location = useLocation();
	const navigate = useNavigate();
	const text = location.state.data ?? '';
	const company = location.state.company ?? '';
	const flow = location.state.flow ?? '';

	console.log('location.state:', location.state);
	console.log('text:', text);
	console.log('company:', company);
	console.log('flow:', flow);

	
	// Define navigation paths
	const totemplatelist = location.state?.from?.pathname || "/list-templates"
	const topdfpreviewer = "/pdf-previewer";
	const errorpage = "/error";

	const setError = () => { };

	useEffect(() => {
		// handleToken();
	})

	// Effect to fetch data when component mounts or certdata, company, or text changes
	useEffect(() => {
		setShowMessage(false);


		//console.log(editor)
		//if ( editor && status === 'ready' ) {
		console.log("inside")
		const fetchUsers = async () => {
			try {
				console.log("inside sync")
				setUserData((userdata) => ({ users: userdata.users, isFetching: false }));

				// Fetch data from the server
				const response = await axios.get(USER_SERVICE_URL, {
					params: {
						templateName: text,
						companyName: company,

					}
				})

				console.log("inside sync" + response.data);


				//const base64 = require('base64topdf')
				//const decodedresponse = base64.decode(response.data)

				// Update state with fetched data
				setUserData({ users: response.data, isFetching: false });
				//console.log(decodedresponse);
				console.log('after set');
				//console.log(editor.getData());
				//editor.setData(response.data)
				setCertData(response.data);

				console.log(certdata);

			} catch (e) {
				// Handle errors during data fetch
				console.log(" errored")
				console.log(e);
				setUserData((userdata) => ({ users: userdata.users, isFetching: false }));
			}
		};
		fetchUsers();
	}, [certdata, company, text]);

	// Function to handle PDF preview navigation
	const handlePreviewPDF = () => {
		handleChange(); // Ensure data is processed before previewing
		setTimeout(() => {
			navigateToPDFPreview();
		}, 2000); // Delay of 2 seconds


		function navigateToPDFPreview() {
			try {
				let temp = location.state.data;

				// Modify the template name to point to the PDF version
				const pdfContent = temp.replace(/\.html$/, '.pdf');
				console.log("pdf content", pdfContent);

				// Navigate to PDF preview page with required state
				navigate(topdfpreviewer, {
					state: {
						filename: pdfContent,
						company: company,
						fromPreview: true,
						flow: "ckeditor"
					},
				});
			} catch (e) {
				// Handle navigation errors
				console.log("Errored while navigating to PDF preview:", e);
				setError("errored");
				navigate(errorpage, { replace: true });
			}
		}

	};

	// Function to handle closing and navigating to another page
	const handleClose = evt => {
		navigate("/choose-template");
	}
	// Function to remove specific HTML tags and add space
	function removeTagsAndAddSpace(htmlString) {
		// Regular expression to match <o:wrapblock>...</o:wrapblock>
		const wrapblockRegex = /<o:wrapblock>.*?<\/o:wrapblock>/gs;
		// Regular expression to match <o:p></o:p>
		const pTagRegex = /<o:p><\/o:p>/g;
	  
		// Replace <o:wrapblock>...</o:wrapblock> with a space
		let result = htmlString.replace(wrapblockRegex, ' ');
		// Replace <o:p></o:p> with a space
		result = result.replace(pTagRegex, ' ');
	  
		return result;
	  }

	// Function to handle changes and submit data
	const handleChange = evt => {
		setLoading(true);

		// Process HTML data
		let tagRemovedData=removeTagsAndAddSpace(editor1.getData());
		const value1 = ((top + styles + body + tagRemovedData + bottom));
		// const customizedData = customizeData(value1);
		// console.log("Get data of ckeditor", editor1.getData());
		// console.log("Beofre customise", value1);

		// Prepare data for submission
		const value = ((value1));
		console.log("Value after customise ", value);
		// console.log (editor1.getData('docx'));
		//dispatch( { type: 'submit', payload: data } );
		//const response = "";
		//console.log(value)
		console.log(text)
		console.log(company)
		//const response =  axios.post(CONVERTER_SERVICE_URL,value);
		const data = {
			companyName: company,
			templateName: text,
			htmlString: value,
			flow: flow
		};
		const response = axios.post(CONVERTER_SERVICE_URL, data, {
			headers: {
				// 'Content-Type': 'text/html',
			}
		})
			.then(response => {
				// Display message after receiving the response
				setShowMessage(true);
				console.log(response);
				setLoading(false);
				setTimeout(() => {
					setShowMessage(false);
				}, 7000);
			})
			.catch(error => {
				// Handle errors if needed
				console.error('Error:', error);
				setLoading(false);
			});
		console.log("inside sync" + response);
	};

	return (
		<div>
			<header>
				<Header />
			</header>
			<div className="container-fluid container-fluid-ex">
				<Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
					<TabList className="hidden-tab-list" >
						<Tab className="hidden-tab-list"></Tab>
					</TabList>
					{/* <LeftNav /> */}
					<TabPanel style={{ width: "96%", marginRight: "2%", marginLeft: "2%" }}>
						<main>
							<div>
								<div class="active-heading" >
									Template Name : {text || 'Editor'}</div>
							</div>
							<div className="container-fluid-buyer">
								<div class="card-listing-section">
									{/* <h2 class="active-menu ml-3">Listings</h2> */}
									<div class="right-wrapper" style={{ margin: "0" }}>
										<div>
											<div className="error-message"><b>To prevent data loss, make sure to click 'Save' button in every 5 minutes.</b></div>

											<div className="editor-container right-wrapper-exy">
												<CKEditor
													editor={ClassicEditor}
													id="ckeditor1"
													data={certdata}
													onReady={editor => {
														// You can store the "editor" and use when it is needed.
														setEditor(editor);
														console.log('Editor is ready to use!');
													}}
													config={{
														watchdog: false,
														title: false,
														heading_styles: {
															'font-size': '8px',
														},


														toolbar:

														{

															shouldNotGroupWhenFull: true
														},
														htmlSupport: {
															allow: [
																{
																	name: /.*/,
																	attributes: true,
																	classes: true,
																	styles: false,
																	title: false
																}
															],
														},
														fontFamily: {
															options: [
																'default',
																'Times New Roman, Times, serif',
																'Book Antiqua, Antiqua serif',
																'Arial, Helvetica, sans-serif',
																'Courier New, Courier, monospace',
																'Georgia, serif',
																'Lucida Sans Unicode, Lucida Grande, sans-serif',
																'Tahoma, Geneva, sans-serif',
																'Trebuchet MS, Helvetica, sans-serif',
																'Verdana, Geneva, sans-serif'

															],
															supportAllValues: true

														},
														fontSize: {
															options: [
																'default',
																9,
																10,
																11,
																12,
																13,
																14,
																15,
																16,
																17,
																18,
																19,
																20,
																21
															]
														},

													}}


												/>
											</div>
											{showMessage && <div><b className="success-message"><green>Template is created! You can close the editor safely!</green></b></div>}
											{loading ? <CircularLoader /> : (<div >
												<div class="btn-container-multiple" style={{ marginTop: "10px" }}>
													<button style={{ height: '28.5px' }}
														class="modal-close-btn" onClick={handleClose}><div style={{ marginTop: '-7.5px' }}><b>Close</b></div></button>
													{flow !== "create" && <button
														class="modal-preview-btn" onClick={handlePreviewPDF}><div style={{ marginTop: '-7.5px' }}><b>Preview PDF</b></div></button>}

													<button style={{ height: '28.5px' }}
														class="modal-submit-btn"
														id="submit"
														type="button"
														value="Submit"
														onClick={handleChange}

													><div style={{ marginTop: '-7.5px' }}><b>Save</b></div></button>
												</div>
											</div>)}
										</div>

									</div>
								</div>

							</div>
						</main>
					</TabPanel>
				</Tabs>
			</div>
		</div>
	);
}

export default CertEditorNew;