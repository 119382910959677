import React from 'react';
import ReactDOM from 'react-dom/client';
import './Css/index.css';
import App from './App';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


<React.StrictMode>
<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet"></link>
  <App/>
</React.StrictMode>
);
