import MaterialTable from "material-table";
import { Icon, IconButton, ThemeProvider, createTheme } from '@mui/material';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ENV from "../config";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';


const defaultMaterialTheme = createTheme();



function SurveyCertificateTable({ data, surveyName, surveyId, companyName, flow, flagPath, status, }) {

  const navigate = useNavigate();
  const location = useLocation();
  const certeditorsurveyor = location.state?.from?.pathname || "/cert-editor-surveyor";
  const topdfpreviewer = location.state?.from?.pathname || "/finalized-pdf-previewer";
  const tosavedpdfpreviewer = location.state?.from?.pathname || "/saved-pdf-previewer";

  const filteredItems = data
    .sort((a, b) => (a.certificateName && b.certificateName) ? a.certificateName.localeCompare(b.certificateName) : 0)
    .filter(surveyCertificateItem => surveyCertificateItem.surveyCertificateStatus === status);
  const [templateNames, setTemplateNames] = useState([]);
  const [initialTemplates, setInitialTemplates] = useState([]);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [selectedTemplateNames, setSelectedTemplateNames] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [templateSubmitted, setTemplateSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTemplateNames();
    data.forEach((template) => {
      console.log(template.certificateName)
    })
    fetchSurveys();
  }, []);

  const fetchTemplateNames = async () => {
    try {
      const templateNamesResponse = await axios.get(ENV.backend + "/api/template");
      const templates = Object.values(templateNamesResponse.data);
      console.log('Fetched Template Names:', templates);
      setTemplateNames(templates);
    } catch (error) {
      console.error("Error fetching template names:", error);
    }
  };

  const fetchSurveys = async () => {
    try {
      const response = await axios.get(ENV.backend + "/api/survey");
      const surveysData = Object.values(response.data);
      console.log("Fetched Surveys:", surveysData);
      setSurveys(surveysData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      setLoading(true);
      // alert("Error fetching surveys");
      localStorage.clear()
      window.location.replace('/')
    }
  };

  const handlePDF = (surveyCertificateId, certificateName, flow) => {
    if (certificateName && certificateName !== null) {
      certificateName = certificateName.replace(".html", ".pdf");
    }

    console.log(certificateName);

    try {
      navigate(topdfpreviewer, {
        state: {
          company: companyName,
          surveyid: surveyId,
          surveyname: surveyName,
          surveycertificateid: surveyCertificateId,
          surveycertificatename: certificateName,
          flow: flow
        },
      });
    } catch (e) {
      console.log("errored");
      console.log(e);
    }
  };

  const handlePDFSave = (surveyCertificateId, certificateName, flow) => {
    if (certificateName && certificateName !== null) {
      certificateName = certificateName.replace(".html", ".pdf");
    }

    console.log(certificateName);

    try {
      navigate(tosavedpdfpreviewer, {
        state: {
          company: companyName,
          surveyid: surveyId,
          surveyname: surveyName,
          surveycertificateid: surveyCertificateId,
          surveycertificatename: certificateName,
          flow: "no-back"
        },
      });
    } catch (e) {
      console.log("errored");
      console.log(e);
    }
  };


  const handleHTML = (surveyCertificateId, certificateName) => {
    try {
      console.log(surveyName);
      console.log(surveyId);
      console.log(companyName);
      console.log(certificateName);
      console.log(flagPath);

      navigate(certeditorsurveyor, {
        state: {
          surveycertificateid: surveyCertificateId,
          surveycertificatename: certificateName,
          company: companyName,
          surveyname: surveyName,
          surveyid: surveyId,
          flagpath: flagPath,
          status: status,
        },
      });
    } catch (e) {
      console.log("errored");
      console.log(e);
    }
  };

  const handleButtonClick = (rowData) => {
    console.log("Button clicked with editor data:", rowData.certificateName);
    console.log(flow)

    /*if (flow === "Approval" || flow === "SurveyorCompleted") {
      handlePDF(rowData.surveyCertificateId, rowData.certificateName, flow);
    } else {
      handleHTML(rowData.surveyCertificateId, rowData.certificateName);
    }*/

    handleHTML(rowData.surveyCertificateId, rowData.certificateName);
  };

  const columns = [
    { title: "Certificate Name", field: "certificateName" },
    { title: "Current Surveyors", field: status === "New" ? "createdBy" : "updatedBy" },
    { title: "Certificate Status", field: "surveyCertificateStatus" },
    {
      title: "Rejection Reason", field: "comment",
      // render: rowData => (
      //   rowData.comment ? rowData.comment.substring(0, 7) + (rowData.comment.length > 7 ? '...' : '') : ''
      // ),
      // detailPanel: rowData => (
      //   <div>
      //     <p>{rowData.comment}</p>
      //   </div>
      // )
    }
  ];

  const handlePreviewClick = (fullComment) => {

    console.log("Full comment:", fullComment);
  };
  const handlePDFPreview = (rowData) => {
    console.log("PDF Preview", rowData.certificateName);
    console.log(flow)

    if (flow === "Approval" || flow === "SurveyorCompleted") {
      handlePDF(rowData.surveyCertificateId, rowData.certificateName, flow);
    } else if (status === "In-Progress") {
      handlePDFSave(rowData.surveyCertificateId, rowData.certificateName, flow);
    }

  };

  const actions = [];
  if (flow !== "Approval" && flow !== "SurveyorCompleted") {
    actions.push(
      {
        icon: "edit",
        tooltip: "Open Editor",
        onClick: (event, rowData) => handleButtonClick(rowData),
        style: { margin: '1px' }
      })
  }


  // Add PDF Preview button if status is "In-Progress"
  if (status === "In-Progress" || flow === "Approval" || flow === "SurveyorCompleted") {
    actions.push({
      icon: "picture_as_pdf",
      tooltip: "PDF Preview",
      onClick: (event, rowData) => handlePDFPreview(rowData),
      style: { margin: '1px' }
    });
  }

  const options = {
    actionsColumnIndex: -1 // Place the actions column at the end
  };

  const actionColumns = [];

  // Check if there are any actions
  if (actions.length > 0) {
    // Iterate over each action
    actions.forEach((action, index) => {
      // Create a new column for the action
      const actionColumn = {
        title: action.tooltip,
        render: rowData => (
          <IconButton
            key={index}
            onClick={event => action.onClick(event, rowData)}
            sx={{ display: "contents", color: "black" }}
          >
            <Icon>{action.icon}</Icon>
          </IconButton>
        )
      };

      // Add the action column to the array of action columns
      actionColumns.push(actionColumn);
    });
  }

  // Combine the original columns with the action columns
  const allColumns = [...columns, ...actionColumns];

  const tableStyle = {
    fontSize: '10px',// Adjust the font size here
  };

  const handleSurveySelection = (surveyId) => {
    console.log("ab", surveyId)
    const selectedSurveyId = surveyId;
    const optionSelectedSurvey = surveys.find((surveyCertificateItem) => surveyCertificateItem.surveyId === selectedSurveyId);

    if (optionSelectedSurvey) {
      const newlySelectedTemplateNames = optionSelectedSurvey.surveyCertificateList.map(
        (certificate) => ({ templateName: certificate.certificateName })

      );
      console.log("sdhbsbjh", optionSelectedSurvey, newlySelectedTemplateNames)
      const updatedLeftTemplates = templateNames.filter((template) => {
        return !newlySelectedTemplateNames.some(
          (selectedTemplate) => selectedTemplate.templateName === template.templateName
        );
      });

      setTemplateNames(updatedLeftTemplates);
      setSelectedSurveys(optionSelectedSurvey)

      console.log('Selected Template Names:', newlySelectedTemplateNames);
      setSelectedTemplateNames(newlySelectedTemplateNames);
      setInitialTemplates(newlySelectedTemplateNames)
    }

    return optionSelectedSurvey;
  };

  const handleSubmit = () => {

    console.log('Submitting selected templates:', selectedTemplateNames, selectedSurveys);

    const temp = selectedTemplateNames.filter((survey) => {
      return !initialTemplates.some((item) => item.templateName === survey.templateName);
    });
    console.log("initial temos", initialTemplates);
    const filteredTemp = temp.filter((survey) => {
      return !initialTemplates.some((initialSurvey) => initialSurvey.templateName === survey.certificateName);
    });
    console.log("temp", temp);
    console.log("filteredTemp", filteredTemp);

    const selectedTemplateNamesArray = temp
      .filter((value) => value.templateName !== null)
      .map((value) => ({
        templateId: parseInt(value.templateId),
        certificateName: value.templateName,
        surveyCertificateStatus: "New",
        createdOn: new Date(),
        createdBy: localStorage.getItem('userFullName'),
        isCloned: false,
      }));
    const updatedSurvey = {
      surveyId: surveyId,
      surveyCertificateList: selectedTemplateNamesArray,
    };


    axios.post(ENV.backend + '/api/survey/' + surveyId, updatedSurvey)
      .then(response => {
        console.log('Survey updated successfully:', response.data);
        setTemplateSubmitted(true);
      })
      .catch(error => {
        console.error('Error updating survey:', error);
        setTemplateSubmitted(true);
      });
  };


  const handleTemplateButtonClick = () => {
    console.log('Selected Templates:', selectedTemplateNames);
    setOpenTemplateDialog(true);
    handleSurveySelection(surveyId)
  };


  const handleTemplateDialogClose = () => {
    console.log('Selected Templates:', selectedTemplateNames);
    setOpenTemplateDialog(false);
    window.location.reload();
  };


  const handleLeftTemplateNamesChange = (templateName) => {
    if (!selectedTemplateNames.includes(templateName)) {
      setSelectedTemplateNames([...selectedTemplateNames, templateName]);


      const updatedLeftTemplates = templateNames.filter(
        (name) => name !== templateName
      );
      setTemplateNames(updatedLeftTemplates);
    }
  };

  const handleRightTemplateNamesChange = (templateName) => {
    const updatedSelectedTemplates = selectedTemplateNames.filter(
      (name) => name !== templateName
    );

    setSelectedTemplateNames(updatedSelectedTemplates);


    setTemplateNames([...templateNames, templateName]);
  };




  return (
    <div style={{ width: '100%', height: '100%' }}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <ThemeProvider theme={defaultMaterialTheme}>
        <div>
          {status === "New" && (
            <Button style={{ color: '#135e62', backgroundColor: '#c9ffec' }} onClick={handleTemplateButtonClick}>
              + Add New Template
            </Button>
          )}
          <MaterialTable
            title="Survey Certificate Table"
            columns={allColumns}
            data={filteredItems}
            // actions={actions}
            options={{
              ...options,
              tableLayout: 'auto', // Allow table to adjust its width dynamically,
              paging:false,
              maxBodyHeight: "45vh",
            }}
            direction="rlt"
            style={tableStyle}
          />
          <Dialog open={openTemplateDialog} onClose={handleTemplateDialogClose} fullWidth maxWidth="md">

            <DialogContent>
              <div>
                <label className="label" htmlFor="templateNames">Template Names</label>
                <div className="template-names-container">
                  <div className="template-names-column left-column">
                    <div className="temp">Template Options</div>
                    {templateNames.map((templateName, index) => (
                      <div
                        key={templateName.templateId}
                        className="template-name-item"
                        onClick={() => handleLeftTemplateNamesChange(templateName)}
                      >
                        {templateName.templateName}
                      </div>
                    ))}
                  </div>
                  <div className="template-names-column right-column">
                    <div className="temp">Selected Templates</div>
                    {selectedTemplateNames && selectedTemplateNames.length === 0 && (

                      <div>
                        {console.log('No selected templates available', selectedTemplateNames)}
                      </div>
                    )}
                    {selectedTemplateNames && selectedTemplateNames.map((selectedName, index) => (
                      <div
                        key={index}
                        className="selected-name-item"
                        onClick={() => handleRightTemplateNamesChange(selectedName)}
                      >
                        {selectedName.templateName}
                        {console.log('Selected Template:', selectedName.templateName)}
                      </div>
                    ))}
                  </div>

                </div>
              </div>
            </DialogContent>
            {templateSubmitted && (
              <h4 style={{ color: 'green', textAlign: 'center' }}>Survey Modified! You Can Close Safely!</h4>
            )}
            <DialogActions>
              <div class="btn-container-multiple">
                <button
                  class="modal-close-btn" onClick={handleTemplateDialogClose}>
                  Close
                </button>
                <button
                  class="modal-submit-btn" onClick={handleSubmit}>
                  Save
                </button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default SurveyCertificateTable;