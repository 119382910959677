import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import Select from "react-select";
import ENV from "../config";
import getVessels from "./Vessels";
import getCountry from "./Country";
import getCompany from "./Company";
import Button from '@mui/material/Button';
import LeftNav from "../Components/LeftNav/LeftNav";
import Header from "../Header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AddBoxIcon from '@mui/icons-material/AddBox';
import handleToken from "../Components/TokenHandler";

const AddCompanyDetails = () => {
 // State variables for managing companies, vessels, countries, and selection states
  //const [company, setCompany] = useState('TEST');
  const [companies, setCompanies] = useState([]);
  const [vessel, setVessel] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState("");
  const [country, setCountry] = useState([]);
  const [selectedCoutry, setSelectedCountry] = useState("");

  // State variables for loading status and input fields
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [showAddVessel, setShowAddVessel] = useState(false);
  const [newCompany, setNewCompany] = useState("");
  const [newVessel, setNewVessel] = useState([]);
  const [imoNumber, setImoNumber] = useState([]);
  const [classId, setClassId] = useState([]);

  // Option selection and error handling
  const [selectOption, setSelectOption] = useState([]); // Set default option as the first option in the options array
  const [error, setError] = useState("");

    // Navigation and location hooks
    const navigate = useNavigate();
    const location = useLocation();

    // Get error page path and go back path from location state
    const errorpage = location.state?.from?.pathname || "/error";
    const goback = location.state?.from?.pathname || "/";

    // Fetch companies when the component mounts
    useEffect(() => {
        fetchCompanies();
        // handleToken();
    }, []);

    // Fetch company data from the API
    const fetchCompanies = async () => {
        try {
            const response = await getCompany(""); // Fetch companies from the backend
            console.log(response);
            setCompanies(response); // Update state with the fetched companies
            //const companyval = [
            //{ value: companies.company.CompanyId, label: companies.company.CompanyName }

            //];
            setIsLoading(false); // Set loading to false once data is fetched
        } catch (error) {
            // Handle error
            console.log("Error:", error); // Log any errors that occur
            setIsLoading(false); // Set loading to false in case of error
        }
    };

    // Function to handle the selection of a vessel
    const handleVesselSelect = (selectedOption) => {
        // Update the state with the selected vessel option
        setSelectedVessel(selectedOption);
    };

    // Generate options for a dropdown or select component
    const options = companies
        // Filter out companies with a null companyName
        .filter((company) => company.companyName !== null)
        // Map each company to an object with value and label properties
        .map((company) => ({
            value: company,
            label: company.companyName,
        }));

    // Function to handle form submission
    const handleSubmit = async (event) => {
        // Prevent the default form submission behavior
        event.preventDefault();
        //const companyId = new Number (selectOption);

        // Convert selected vessel and country to numeric values
        const vesselId = new Number(selectedVessel);
        const countryId = new Number(selectedCoutry);

        //console.log(companyId);
        console.log(vesselId);

        // Create a new company object with form data
        const newCompany = {
            // Assuming selectOption is the selected company object
            companyId: selectOption.value,
            companyName: "DAKAR",  // Hardcoded company name; adjust as needed

      vesselId: vesselId,
      countryId: countryId,

      createdBy: "Liz Mat",
      updatedBy: "Liz Mat",
      createdOn: new Date(),
      updatedOn: new Date(),
        };

     // Log the new company object to the console for debugging
    console.log(newCompany);

    // try {
    //   // Make an API call to the backend to create a new user
    //   const response = await axios.post(
    //     ENV.backend+"/api/company",
    //     newCompany
    //   );

    //   if (response.status === 201) {
    //     // User created successfully
    //     console.log("Company created!");
    //     // Additional actions if needed
    //   } else {
    //     // Error occurred while creating the user
    //     console.error("Error creating Company");
    //     // Additional error handling if needed
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    //   // Additional error handling if needed
    // }

    // Reset input fields and selections
  };

    // Function to handle the selection of a company
    const handleCompanySelect = async (selectedOption) => {
        // Log the selected company option for debugging
        console.log(selectedOption);
        // setSelectOption(value);

        // Update the selected company and reset vessel selection
        setSelectedCompany(selectedOption);
        setSelectedVessel(null);

    try {
            // Fetch vessels associated with the selected company
      const response = await getVessels("", selectedOption.value);
      console.log(response);

        // Filter and map the vessels to create a list of options for the vessel dropdown
        const v = response.filter((v) => v.companyId === selectedOption.value.companyId)
            .map((v) => ({
                value: v,
                label: v.vesselName,
            }));
        // Update the state with the filtered vessel options
        setVessel(v)

    } catch (error) {
        // Handle error
        // Handle any errors that occur during the fetch
      console.log("Error:", error);
    }
    };

    // Function to show the form for adding a new company
    const handleAddCompany = () => {
        setShowAddCompany(true);
    };

    // Function to close the form for adding a new company without submitting
    const closeAddCompanySubmit = () => {
        setShowAddCompany(false)
    }

    // Function to handle form submission for adding a new company
    const handleAddCompanySubmit = async () => {

        // Create a new company object with the provided company name and empty lists for vessels and countries
        const newCompanyObject = {
            companyName: newCompany,
            vesselList: [],
            countryList: [],
        };

    try {
      // Make an API call to the backend to create a new user
      const response = await axios.post(
        ENV.backend + "/api/company",
        newCompanyObject
      );

      if (response.status === 201) {
          // Company created successfully
          console.log("Company created!", response);
          // Add the newly created company to the list of companies in the state
        setCompanies([...companies, response.data]);
      } else {
          // Error occurred while creating the company
          console.error("Error creating Company");
          // Additional error handling if needed
      }
    } catch (error) {
      console.error("Error:", error);
      // Additional error handling if needed
    }

     // Clear the new company input field and hide the add company form
    // setCompanies([...companies, newCompanyObject]);
    setNewCompany("");
    setShowAddCompany(false);

  };

    // Function to show the form for adding a new vessel
    const handleAddVessel = () => {
        setShowAddVessel(true);
    };

    // Function to close the form for adding a new vessel without submitting
    const closeAddVesselSubmit = () => {
        setShowAddVessel(false);
    }

    // Function to handle form submission for adding a new vessel
    const handleAddVesselSubmit = async (event) => {
        // Prevent the default form submission behavior
        event.preventDefault();
        // Log the selected company for debugging purposes
        console.log(selectedCompany)

        // Create a new vessel object with the provided details
    const newVesselObject = {
      companyId: selectedCompany.value.companyId,
      vesselName: newVessel,
      imoNumber: imoNumber,
      classId: classId,
      createdOn: new Date(),  // Set current date and time
      createdBy: localStorage.getItem('userFullName'),
    };



    console.log(newVesselObject)

    try {
      // Make an API call to the backend to create a new user
      const response = await axios.post(
        ENV.backend + "/api/vessel",
        newVesselObject
      );

      if (response.status === 201) {
          // Vessel created successfully
          console.log("respo", response);

          // Format the new vessel for display in the vessel list
        const formattedVessel = {
          value: response.data,
          label: newVesselObject.vesselName,
        };

          // Add the newly created vessel to the state
          setVessel([...vessel, formattedVessel]);

          console.log("Vessel created!");
      } else {
          // Error occurred while creating the vessel
          console.error("Error creating Vessel");
          // Additional error handling if needed
      }
    } catch (error) {
      console.error("Error:", error);
      // Additional error handling if needed
    }

        // Clear the input fields and hide the add vessel form
    setNewVessel("");
    setClassId("");
    setImoNumber("");
    setShowAddVessel(false);
  };

    // Function to handle closing the current view and navigating back
  function handleClose(event) {
    navigate(goback);
  }

  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "87%" }}>
            <main>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper" style={{ minHeight: '63vh' }}>
                    <h1 className="heading-survey">Client Details</h1>
                    <label className="label" htmlFor="roles">Client</label>
                    <div className="select-container">
                      <div className="custom-select">
                        <Select
                          options={options} className="select-template"
                          defaultValue={selectedCompany}
                          onChange={handleCompanySelect} //{setSelectedOption}
                          required
                        />
                      </div>
                      {!showAddCompany && (
                        <div className="button-container">
                          <AddBoxIcon sx={{ color: "green", width: '40px', height: '40px', marginTop: "-1px", marginRight: "-5px" }} onClick={handleAddCompany}></AddBoxIcon>
                        </div>
                      )}
                    </div>


                    {showAddCompany && (
                      <div>
                        <div id="id02" className="submodal">
                          <div className="modal-content animate">
                            <div className="submodal-container">
                              <h2 className="client-add-heading">Add New Client</h2>
                             
                              <input
                                type="text"
                                value={newCompany}
                                onChange={(e) => setNewCompany(e.target.value)}
                                placeholder="Client Name"
                              />
                              <div class="btn-container-multiple">
                                <button className="modal-close-btn" onClick={closeAddCompanySubmit}><b>Close</b></button>
                                <button className="modal-submit-btn" onClick={handleAddCompanySubmit}><b>Save</b></button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                    {selectedCompany && (

                      <div>
                        <br></br>
                        <label className="label">Vessel</label>
                        <div className="select-container">
                          <div className="custom-select">
                            <Select
                              options={vessel} className="select-template"
                              value={selectedVessel}
                              onChange={handleVesselSelect}
                              placeholder="Select a  vessel"
                            />
                          </div>
                          <div className="button-container">
                            <AddBoxIcon sx={{ color: "green", width: '40px', height: '40px', marginTop: "-1px", marginRight: "-5px" }} onClick={handleAddVessel}>+</AddBoxIcon>
                          </div>
                        </div>
                      </div>
                    )}

                    {showAddVessel && (
                      <div id="id02" className="submodal">
                        <div className="modal-content animate">
                          <div className="submodal-container">
                            <h2 className="client-add-heading">Add New Vessel</h2>     
                              <input
                              type="text"
                              value={newVessel}
                              onChange={(e) => setNewVessel(e.target.value)}
                              placeholder="Vessel Name"
                            />
                            <input
                              type="text"
                              value={classId}
                              onChange={(e) => setClassId(e.target.value)}
                              placeholder="Add Class Id"
                            />
                            <input
                              type="text"
                              value={imoNumber}
                              onChange={(e) => setImoNumber(e.target.value)}
                              placeholder="Add IMO Number"
                            />
                            <div class="btn-container-multiple">
                              <button className="modal-close-btn" onClick={closeAddVesselSubmit}>Close</button>
                              <button className="modal-submit-btn" onClick={handleAddVesselSubmit}>Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}



                    <br></br>
                    {/* <Button style={{ color: '#ffc9c9', backgroundColor: '#8c111f', textTransform: 'none' }} onClick={handleClose}>
          <b>Close </b>
        </Button> */}
                  </div>
                </div>

              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div>
    </div>

  );
};

export default AddCompanyDetails;
