import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import '../../../Css/pages.css';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Box } from '@mui/material';
import Header from '../../../Header/Header';
import LeftNav from '../../LeftNav/LeftNav';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const AddRequest = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const flow = location.state?.flow || 'create';
    const request = location.state?.request || {};

    const [name, setName] = useState(request.name || '');
    const [vessel, setVessel] = useState(request.vessel || '');
    const [vesseltype, setVesselType] = useState(request.vesselType || '');
    const [surveytype, setSurveyType] = useState(request.surveyType || '');
    const [priority, setPriority] = useState(request.priority || '');
    const [remarks, setRemarks] = useState(request.remarks || '');
    const [startDate, setStartDate] = useState(request.startDate || null);
    const [endDate, setEndDate] = useState(request.endDate || null);
    const [showMessage, setShowMessage] = useState(false);

    const handleCancel = () => {
        navigate('/requestlist');
    };

    const handleSubmit = () => {
        if (flow === 'edit') {
            
        } else {
            
        }
        setShowMessage(true);
    };

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list">
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div className="card-listing-section">
                                    <div className="right-wrapper">
                                        <div>
                                            <h1 className="titlereg ph-view">{flow === 'edit' ? 'Update Request' : 'Create Request'}</h1>
                                            <div>
                                                <form className="formregister">
                                                    <label>
                                                        <input id='name'
                                                            placeholder="Name" type="text" className="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => { setName(e.target.value) }}
                                                            value={name}
                                                            required
                                                        />
                                                    </label>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="rolcodees" id="select-labl" sx={{
                                                            "&.MuiInputLabel-root": {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI Historic'
                                                            },
                                                            "&.MuiInputLabel-shrink": {
                                                                marginTop: '0px'
                                                            }
                                                        }}>Vessel</InputLabel>
                                                        <Select sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }} id='roles' labelId="select-labl" label="Vessel"
                                                            onChange={(e) => setVessel(e.target.value)} value={vessel}>
                                                            <MenuItem value='' disabled>Select a Vessel</MenuItem>
                                                            <MenuItem value='Vessel A'>Vessel A</MenuItem>
                                                            <MenuItem value='Vessel B'>Vessel B</MenuItem>
                                                            
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="rolcodees" id="select-labl" sx={{
                                                            "&.MuiInputLabel-root": {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI Historic'
                                                            },
                                                            "&.MuiInputLabel-shrink": {
                                                                marginTop: '0px'
                                                            }
                                                        }}>Vessel Type</InputLabel>
                                                        <Select sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }} id='roles' labelId="select-labl" label="Vessel Type"
                                                            onChange={(e) => setVesselType(e.target.value)} value={vesseltype}>
                                                            <MenuItem value='' disabled>Select a Vessel Type</MenuItem>
                                                            <MenuItem value='Type 1'>Type 1</MenuItem>
                                                            <MenuItem value='Type 2'>Type 2</MenuItem>
                                                            
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="rolcodees" id="select-labl" sx={{
                                                            "&.MuiInputLabel-root": {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI Historic'
                                                            },
                                                            "&.MuiInputLabel-shrink": {
                                                                marginTop: '0px'
                                                            }
                                                        }}>Survey Type</InputLabel>
                                                        <Select sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }} id='roles' labelId="select-labl" label="Survey Type"
                                                            onChange={(e) => setSurveyType(e.target.value)} value={surveytype}>
                                                            <MenuItem value='' disabled>Select a Survey Type</MenuItem>
                                                            <MenuItem value='Survey X'>Survey X</MenuItem>
                                                            <MenuItem value='Survey Y'>Survey Y</MenuItem>
                                                           
                                                        </Select>
                                                    </FormControl>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: '10px', marginTop: '10px' }}>
                                                            <DatePicker sx={{
                                                                '.MuiOutlinedInput-root': {
                                                                    borderRadius: '10px',
                                                                }, '.MuiInputBase-input': {
                                                                    fontFamily: 'Segoe UI Historic',
                                                                    margin: '6px 0'
                                                                },
                                                                '.MuiFormLabel-root': {
                                                                    fontFamily: 'Segoe UI Historic',
                                                                    marginTop: '-6px',
                                                                },
                                                            }}
                                                                label="Start Date"
                                                                value={startDate}
                                                                onChange={(newValue) => setStartDate(newValue)}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                            <DatePicker sx={{
                                                                '.MuiOutlinedInput-root': {
                                                                    borderRadius: '10px',
                                                                }, '.MuiInputBase-input': {
                                                                    fontFamily: 'Segoe UI Historic',
                                                                    margin: '6px 0'
                                                                },
                                                                '.MuiFormLabel-root': {
                                                                    fontFamily: 'Segoe UI Historic',
                                                                    marginTop: '-6px',
                                                                },
                                                            }}
                                                                label="End Date"
                                                                value={endDate}
                                                                onChange={(newValue) => setEndDate(newValue)}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="rolcodees" id="select-labl" sx={{
                                                            "&.MuiInputLabel-root": {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI Historic'
                                                            },
                                                            "&.MuiInputLabel-shrink": {
                                                                marginTop: '0px'
                                                            }
                                                        }}>Priority</InputLabel>
                                                        <Select sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }} id='roles' labelId="select-labl" label="Priority"
                                                            onChange={(e) => setPriority(e.target.value)} value={priority}>
                                                            <MenuItem value='' disabled>Select Priority</MenuItem>
                                                            <MenuItem value='High'>High</MenuItem>
                                                            <MenuItem value='Medium'>Medium</MenuItem>
                                                            <MenuItem value='Low'>Low</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <label>
                                                        <input id='remarks'
                                                            placeholder="Remarks" type="text" className="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => { setRemarks(e.target.value) }}
                                                            value={remarks}
                                                            required
                                                        />
                                                    </label>
                                                </form>
                                            </div>
                                        </div>
                                        {showMessage && <div><b><green>Request {flow === 'edit' ? 'updated' : 'created'} successfully!!!</green></b></div>}
                                        <div className="btn-container-multiple">
                                            <button className="modal-submit-btn" type="submit" onClick={handleSubmit}><b>Save</b></button>
                                            <button style={{ marginRight: "23px" }} className="modal-close-btn" onClick={handleCancel}><b>Close</b></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}

export default AddRequest;
