import React from 'react'
import { Link } from 'react-router-dom'
import ENV from "../config";
const Home = () => {
  return (
    <div className='App'>
      <h1>Home</h1>
      <p>You are logged in</p><br/>
    </div>
  )
}

export default Home
