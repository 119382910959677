import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Button } from '@mui/material';
import { Search, Add, Edit, Visibility } from '@mui/icons-material';
import './ClientVesselMap.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../Header/Header';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import LeftNav from '../../LeftNav/LeftNav';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText } from '@mui/material';
import getCompany from '../../../Pages/Company';
import getVessels from '../../../Pages/Vessels';
import axios from 'axios';
import env from '../../../config';
import Snackbars from '../../Snackbar';

const ClientVesselMap = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // State variables to manage component data and UI states
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [vessels, setVessels] = useState();
    const [companies, setCompanies] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([])

    // Snackbar states for notifications
    const [openCreateSnackbar, setOpenCreateSnackbar] = useState();
    const [openUpdateSnackbar, setOpenUpdateSnackbar] = useState();

    // Function to close the create notification snackbar
    const handleCloseCreateSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;  // Prevent closing when clicking away from the snackbar
        }
        setOpenCreateSnackbar(false);  // Close the snackbar
    };

    // Function to close the update notification snackbar
    const handleCloseUpdateSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;  // Prevent closing when clicking away from the snackbar
        }
        setOpenUpdateSnackbar(false);  // Close the snackbar
    };

    // Effect to fetch mapping data when the component mounts
    useEffect(() => {
        settingData();
    }, []);

    // Effect to show the appropriate snackbar based on the state from navigation
    useEffect(() => {
        if (location.state?.data?.create) {
            setOpenCreateSnackbar(true)
        }
        else if (location.state?.data?.update) {
            setOpenUpdateSnackbar(true)
        }
    }, []);

    // Function to fetch client-vessel mapping data from the backend
    const settingData = async () => {
        const response = await axios.get(env.backend + '/api/ClientVesselMapping', {});
        setData(response.data);
    }

    // Function to handle changes in the search input field
    const handleSearchChange = (event) => {
        setSearch(event.target.value);  // Update search state with the current input value
    };

    // Filter clientVesselMap list based on the search query
    const filteredData = data.filter(item => {
        const clientName = item.clientName ? item.clientName : '';
        return clientName.toLowerCase().includes(search.toLowerCase());
    });


    // Function to navigate to the page for creating a new client-vessel mapping
    const handleCreateNewCompany = () => {
        navigate('/clientVesselMap/newMap');
    };

    // Function to handle viewing details of a selected vessel
    const handleViewVessels = (vesselInfoJson) => {
        const data = JSON.parse(vesselInfoJson);  // Parse JSON string to object
        console.log("vsedme", data);  // Log vessel data for debugging

        setSelectedClient(data);  // Set the selected client information
        setOpen(true);  // Open the modal/dialog to display vessel details
    };

    // Function to navigate to the page for editing a specific client-vessel mapping
    const handleEditCompany = (data) => {
        navigate('/clientVesselMap/newMap', {
            state: {
                data: data,  // Pass the data to the new map page
                isUpdate: true,  // Flag to indicate that this is an update operation
            },
        });
    };

    // Function to close the modal/dialog and reset selected client state
    const handleClose = () => {
        setOpen(false);  // Close the modal/dialog
        setSelectedClient(null);  // Clear selected client information
    };

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    
                                    <div class="right-wrapper">
                                        <div >
                                            <h1 class="titlereg" style={{ marginLeft: '-21px' }}> Client Vessel Mapping</h1>
                                            <Paper>
                                                <div className="search-bar-container" style={{ marginTop: '-23px' }}>
                                                    <TextField
                                                        className="search-bar"
                                                        label="Search"
                                                        variant="outlined"
                                                        value={search}
                                                        onChange={handleSearchChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <Search />
                                                            ),
                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI Historic',
                                                            },
                                                            '& .MuiInputLabel-shrink': {
                                                                marginTop: '0px',
                                                            },
                                                            '& .MuiOutlinedInput-input': {
                                                                height: "25px",
                                                                margin: '4px',
                                                                fontFamily: 'Segoe UI Historic',
                                                            }
                                                        }}
                                                    />
                                                    
                                                    <AddToPhotosIcon sx={{ fontSize: "2rem", color: "green", cursor: "pointer" }} onClick={handleCreateNewCompany} />
                                                </div>
                                                <TableContainer className="table-container">
                                                    <Table>
                                                        <TableHead className="table-header">
                                                            <TableRow>
                                                                <TableCell className='table-head-new' style={{ width: "28%" }}>Client Name</TableCell>
                                                                <TableCell className='table-head-new' style={{ width: "28%" }}>Vessels</TableCell>
                                                                <TableCell className='table-head-new' style={{ width: "15%" }}>Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {filteredData.map((row) => (
                                                                <TableRow key={row.clientId}>
                                                                    <TableCell className="table-cell-survey">{row.clientName}</TableCell>
                                                                    <TableCell className="table-cell-survey">
                                                                        <div className="action-buttons">
                                                                            <IconButton sx={{ padding:'2px' }} className="action-button" onClick={() => handleViewVessels(row.vesselInfoJson)}>
                                                                                <Visibility />
                                                                            </IconButton>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell className="table-cell-survey">
                                                                        <div className="action-buttons">
                                                                            <IconButton sx={{ padding: '2px' }} className="action-button" onClick={() => handleEditCompany(row)}>
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                                                <DialogTitle class="titlereg" style={{ marginTop: "10px" }}>Mapped Vessels</DialogTitle>
                                                <DialogContent>
                                                    <List>
                                                        <TableContainer className="table-container">
                                                            <Table>
                                                                <TableHead className="table-header">
                                                                    <TableRow>
                                                                        <TableCell className='table-head-new' style={{ width: "28%" }}>Vessel Name</TableCell>
                                                                        <TableCell className='table-head-new' style={{ width: "28%" }}>IMO</TableCell>
                                                                        <TableCell className='table-head-new' style={{ width: "28%" }}>Vessel Type</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {selectedClient?.map((vessel, index) => (
                                                                        <TableRow key={vessel.vesselId}>
                                                                            <TableCell className="table-cell-survey">{vessel.vesselName}</TableCell>
                                                                            <TableCell className="table-cell-survey">{vessel.imoNumber}</TableCell>
                                                                            <TableCell className="table-cell-survey">{vessel.vesselType}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </List>
                                                </DialogContent>
                                            </Dialog>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
            <Snackbars
                openSnackbar={openCreateSnackbar}
                handleCloseSnackbar={handleCloseCreateSnackbar}
                type="success"
                message={"Vessel Mapping created successfully"}
            />
            <Snackbars
                openSnackbar={openUpdateSnackbar}
                handleCloseSnackbar={handleCloseUpdateSnackbar}
                type="success"
                message={"Vessel Mapping updated successfully"}
            />
        </div>

    );
};

export default ClientVesselMap;
