import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const ViewVessel = () => {
    const location = useLocation();
    const vessel = location.state?.vessel;

    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/vessel', {
            state: {
                search: location.state?.search
            }
        });
    };

    if (!vessel) {
        return <Typography variant="h6">No vessel data available</Typography>;
    }

    return (
        <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
            <div className="header-container">
                <h1><div className="heading-survey" >View Vessel</div></h1>
            </div>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Vessel Name</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{vessel.vesselName}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Client</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{vessel.companyName}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">IMO Number</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{vessel.imoNumber}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Class ID</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{vessel.classId}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Client ID</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{vessel.companyId}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Vessel Type</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{vessel.vesselType}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Flag</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{vessel.flag}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Gross Tonnage/Net Tonnage</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{vessel.grossTonnage}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Port Registry</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{vessel.portOfRegistry}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Distinctive Number of Letters/Call Sign</Typography>
                    <Typography variant="body5" class="table-grid-new-more">{vessel.distinctiveNoOfLetters}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Length Breadth width </Typography>
                    <Typography variant="body5" class="table-grid-new-more">{vessel.lengthBreadthWidth}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Date of Construction </Typography>
                    <Typography variant="body5" class="table-grid-new-more">
                    {dayjs.utc(vessel.dateOfConstruction).format("D MMM YYYY")}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Keel Laid Date</Typography>
                    <Typography variant="body5" class="table-grid-new-more">
                    {dayjs.utc(vessel.keelLaidDate).format("D MMM YYYY")}
                    </Typography>
                </Grid>


                {/* Add more fields as needed */}

            </Grid>
            <div className="btn-container-multiple" style={{ marginRight: "20px" }}>
                <button style={{ marginRight: "25px" }} className="modal-close-btn" onClick={handleCancel}><b>Close</b></button>
            </div>
        </Paper>
    );
};

export default ViewVessel;
