

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom'
import '../../../Css/pages.css';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Header from '../../../Header/Header';
import LeftNav from '../../LeftNav/LeftNav';
import { FaEye, FaEyeSlash } from "react-icons/fa";

const UserRegistartion = () => {

    const [gender, setGender] = useState('');

    const handleGenderChange = (event) => {
        setGender(event.target.value);
    }

    const [showPassword, setShowPassword] = useState(false);
    const [showMatchPassword, setShowMatchPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const goback = location.state?.from?.pathname || "/";
    const from = location.state?.from?.pathname || "/signin"
    const [locate, setLocate] = useState('');
    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [validName, setValidName] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [password, setPassword] = useState('');
    const [matchPassword, setMatchPassword] = useState('');
    const [validPass, setValidPass] = useState(false); 
    const [checkMatch, setCheckMatch] = useState(false)
    const [email, setEmail] = useState('');
    const [number, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('');
    const [roles, setRoles] = useState('');
    const [surveycompany, setSurveyCompany] = useState('');
    const [error, setError] = useState('');

    const handleCancel = () => {
        navigate('/users');
    };

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid container-fluid-ex">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper right-wrapper-ex">
                                        <div >
                                            <h1 class="titlereg"> User Register</h1>
                                            <div>
                                                <form class="formregister">
                                                    <label >

                                                        <input id='fname'
                                                            placeholder="First Name" type="text" class="inputreg"s
                                                            autoComplete='off'
                                                            onChange={(e) => { setFName(e.target.value) }}
                                                            value={fname}
                                                            required
                                                        />


                                                       

                                                    </label>


                                                    <label >
                                                        <input id='lname'
                                                            placeholder="Last Name" type="text" class="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => { setLName(e.target.value) }}
                                                            value={lname}
                                                            required
                                                        />
                                                      
                                                    </label>


                                                    <label>

                                                        <input id='email'
                                                            placeholder="Email" type="email" class="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => { setEmail(e.target.value) }}
                                                            value={email}
                                                            required
                                                        />
                                                        
                                                    </label>

                                                    <label>
                                                        <input
                                                            id='country'
                                                            placeholder="Country"
                                                            type="text"
                                                            className="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => { setCountry(e.target.value) }}
                                                            value={country}
                                                            required
                                                        />
                                                       
                                                    </label>

                                                    <label>

                                                        <input id='number'
                                                            placeholder="Phone Number" type="tel" class="inputreg"
                                                            autoComplete='off'

                                                            onChange={(e) => { setPhoneNumber(e.target.value) }}
                                                            value={number}
                                                            required
                                                        />
                                                        
                                                    </label>



                                                    <div >
                                                        <label>Gender</label>
                                                        <RadioGroup value={gender} onChange={handleGenderChange} className="flexreg"

                                                            sx={{
                                                                fontFamily: 'Segoe UI Historic',
                                                                '& .MuiFormControlLabel-root': {
                                                                    fontFamily: 'Segoe UI Historic',
                                                                },
                                                                '& .MuiRadio-root': {
                                                                    fontFamily: 'Segoe UI Historic',
                                                                }
                                                            }}

                                                        >
                                                            <FormControlLabel
                                                                value="male"
                                                                control={<Radio />}
                                                                label="Male"
                                                            />
                                                            <FormControlLabel
                                                                value="female"
                                                                control={<Radio />}
                                                                label="Female"
                                                            />
                                                        </RadioGroup>
                                                    </div>

                                                    <div class="flexreg">




                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="roles" id="select-labl" sx={{
                                                                "&.MuiInputLabel-root": {
                                                                    marginTop: '-11px',
                                                                    fontFamily: 'Segoe UI Historic'
                                                                },
                                                                "&.MuiInputLabel-shrink": {
                                                                    marginTop: '0px'
                                                                }
                                                            }}>Role</InputLabel>


                                                            <Select sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }} id='roles' labelId="select-labl" label="userroles"
                                                                onChange={(e) => setRoles(e.target.value)}>
                                                                {console.log(roles)}
                                                                <MenuItem value='' disabled selected className="dropdown-list">Select a Role</MenuItem>
                                                                <MenuItem value='ADMIN' className="dropdown-list">Administrator</MenuItem>
                                                                <MenuItem value='USER' className="dropdown-list">Template Creator</MenuItem>
                                                                <MenuItem value='SURVEYOR' className="dropdown-list">Surveyor</MenuItem>
                                                                <MenuItem value='PROJECTMANAGER' className="dropdown-list">Project Manager</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl fullWidth>

                                                            <InputLabel htmlFor='locate' id="select-label" sx={{
                                                                "&.MuiInputLabel-root": {
                                                                    marginTop: '-11px',
                                                                    fontFamily: 'Segoe UI Historic'
                                                                },
                                                                "&.MuiInputLabel-shrink": {
                                                                    marginTop: '0px'
                                                                }
                                                            }}>Location</InputLabel>

                                                            <Select sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                                                labelId="select-label"
                                                                id="locate"

                                                                label="Locate"
                                                                onChange={(e) => setLocate(e.target.value)}
                                                            >


                                                                <MenuItem value='' disabled selected className="dropdown-list">Select</MenuItem >
                                                                <MenuItem value='HEADOFFICE' className="dropdown-list">HEADOFFICE</MenuItem >
                                                                <MenuItem value='KOCHI' className="dropdown-list">KOCHI</MenuItem >
                                                                <MenuItem value='DUBAI' className="dropdown-list">DUBAI</MenuItem >
                                                            </Select>

                                                        </FormControl>

                                                    </div>
                                                    <div className="password-input-container">
                                                        <input style={{
                                                            border: "1px solid rgba(105, 105, 105, 0.397)",
                                                            borderRadius: "10px", height: "36px",
                                                            margin: '0px',
                                                            fontFamily: 'Segoe UI Historic'
                                                        }}
                                                            type={showPassword ? 'text' : 'password'}
                                                            id='password'
                                                            className="rounded-input"
                                                            placeholder="Password"
                                                            onChange={(e) => { setPassword(e.target.value) }}
                                                            required
                                                            value={password}
                                                        />
                                                        {password && (
                                                            <div
                                                                className="toggle-password-icon"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                            >
                                                                {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="password-input-container">
                                                        <input style={{border: "1px solid rgba(105, 105, 105, 0.397)",
                                                            borderRadius: "10px", height: "36px",
                                                            margin: '0px',
                                                            fontFamily: 'Segoe UI Historic'
                                                        }}
                                                            type={showMatchPassword ? 'text' : 'password'}
                                                            id='matchpassword'
                                                            className="rounded-input"
                                                            placeholder="Confirm Password"
                                                            onChange={(e) => setMatchPassword(e.target.value)}
                                                            required
                                                            value={matchPassword}
                                                        />
                                                        {matchPassword && (
                                                            <div
                                                                className="toggle-password-icon"
                                                                onClick={() => setShowMatchPassword(!showMatchPassword)}
                                                            >
                                                                {showMatchPassword ? <FaEye /> : <FaEyeSlash />}
                                                            </div>
                                                        )}
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                        
                                        {showMessage && <div><b><green>New profile created successfully!!!</green></b></div>}
                                        <div class="btn-container-multiple" style={{ marginTop: "0px" }} >
                                            <button style={{ marginRight: "23px" }} class="modal-submit-btn" type="submit"><b>Save</b></button>
                                            <button style={{ marginRight: "23px" }} className="modal-close-btn" onClick={handleCancel}><b>Close</b></button>
                                        </div>                                     
                                    </div>
                                </div>

                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>


    )
}

export default UserRegistartion
