import './Css/App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";


import Register from './Register';
import Signin from './Signin';
import Home from './Pages/Home';
import LinkPage from './Pages/LinkPage';
import Unauthorized from './Pages/Unauthorized';
import Auth from './Pages/Auth';
import CertEditor from './Pages/Admin/CertEditor';
import TemplateChooser from './Pages/Admin/TemplateChooser'
import TemplateList from './Pages/Admin/TemplateList';
import PdfPreview from './Pages/Admin/PdfPreview';
import FinalizedPdfPreview from './Pages/FinalizedPdfPreview';
import SavedPdfPreview from './Pages/SavedPdfPreview';
import ApprovedFinalizedPdfPreview from './Pages/ApprovedFinalizedPdfPreview';
import ErrorPage from './Pages/ErrorPage';
import QRCodeGenerator from './Pages/QRCodeGenerator';
import CreateSurvey from './Pages/CreateSurvey';
import SurveyDashboard from './Pages/Dashboards/SurveyDashboard';
import SurveyorDashboard from './Pages/Dashboards/SurveyorDashboard';
import ApproverDashboard from './Pages/Dashboards/ApproverDashboard';
import CertEditorSurveyor from './Pages/CertEditorSurveyor';
import AddCompanyDetails from './Pages/AddCompanyDetails';
import { AuthProvider } from './Context/authProvider';
import { useEffect } from 'react';
import LandingPage from './Pages/LandingPage/LandingPage';
import CertEditorNew from './Pages/Admin/CertEditorNew';
import UserRegistartion from './Components/Users/Registration/UserRegistration';
import UserList from './Components/Users/UserList/UserList';
import CompanyTable from './Components/Company/CompanyList/CompanyList';
import DepartmentTable from './Components/Department/DepartmentList/DepartmentList';
import VesselTable from './Components/Vessel/VesselList/VesselList';
import TaskItem from './Components/TaskItem/TaskItem/TaskItem';
import AddVessel from './Components/Vessel/AddVessel/AddVessel';
import AddDepartment from './Components/Department/AddDepartment/AddDepartment';
import AddCompany from './Components/Company/AddCompany/AddCompany';
import ViewPage from './Components/Department/ViewDepartment/ViewDepartment';
import ViewCertificates from './Pages/Dashboards/ViewCertificates';
import RequestList from './Components/RequestList/RequestList/RequestList';
import AddRequest from './Components/RequestList/AddRequest/AddRequest';
import ViewSurveys from './Components/ViewSurveys/ViewSurveys/ViewSurveys';
import MainDashboard from './Pages/Dashboards/MainDashboard';
import ViewReqPage from './Components/RequestList/ViewRequestList/ViewRequestList';
import ViewMoreSurvey from './Components/ViewSurveys/ViewMoreSurvey/ViewmoreSurvey';
import ViewCompany from './Components/Company/ViewCompany/ViewCompany';
import ViewVessel from './Components/Vessel/ViewVessel/ViewVessel';
import ViewUser from './Components/Users/ViewUser/ViewUser';
import ViewItem from './Components/TaskItem/ViewItem/ViewItem';
import ItemList from './Components/TaskItem/ItemList/ItemList';
import ResetPassword from './Components/ResetPassword/Resetpassword';
import SurveyChecklist from './Pages/SurveyChecklist/SurveyChecklist';
import Attachments from './Pages/Attachments/Attachments';
import Checklist from './Pages/Checklist/Checklist';
import ClientVesselMap from './Components/Company/ClientVesselMap/ClientVesselMap';
import CreateClientVesselMap from './Components/Company/CreateClientVesselMap/CreateClientVesselMap';



const superadmin = ['Super Admin'];
const admin = ['Super Admin', 'Admin', "CertPro Admin"];
const projectmanager = ['Super Admin', 'Admin', 'Project Manager', "CertPro Admin"];
const surveyor = ['Super Admin', 'Admin', 'Project Manager', 'Surveyor', "CertPro Admin"];
const user = ['Super Admin', 'Admin', 'Project Manager', 'Surveyor', 'User', "CertPro Admin"];
const dashboardview = ['Super Admin', 'Admin', 'Project Manager', "CertPro Admin","Surveyor"];
const userRole = localStorage.getItem('role')


function App() {

  return (
    <BrowserRouter>
      <div>
        <main>
          <div>
            <Routes>

              <Route path="/" element={<LandingPage />} />
              <Route path='home' element={<Home />} />
              <Route path="/choose-template" element={(admin.includes(userRole)) ? <TemplateChooser /> : <Unauthorized />} />
              <Route path='/list-templates' element={(admin.includes(userRole)) ? <TemplateList /> : <Unauthorized />} />
              <Route path='/survey-dashboard' element={(projectmanager.includes(userRole)) ? <SurveyDashboard /> : <Unauthorized />} />
              <Route path='/surveyor-dashboard' element={(surveyor.includes(userRole)) ? <SurveyorDashboard /> : <Unauthorized />} />
              <Route path='/approver-dashboard' element={(projectmanager.includes(userRole)) ? <ApproverDashboard /> : <Unauthorized />} />
              <Route path='/add-company-details' element={(projectmanager.includes(userRole)) ? <AddCompanyDetails /> : <Unauthorized />} />
              <Route path='/create-survey' element={(projectmanager.includes(userRole)) ? <CreateSurvey /> : <Unauthorized />} />
              <Route path='/signin' element={<Signin />} />
              <Route path='/approved-finalized-pdf-previewer' element={(projectmanager.includes(userRole)) ? <ApprovedFinalizedPdfPreview /> : <Unauthorized />} />
              <Route path='/saved-pdf-previewer' element={(surveyor.includes(userRole)) ? <SavedPdfPreview /> : <Unauthorized />} />
              <Route path='/finalized-pdf-previewer' element={(surveyor.includes(userRole)) ? <FinalizedPdfPreview /> : <Unauthorized />} />
              <Route path='/pdf-previewer' element={(admin.includes(userRole)) ? <PdfPreview /> : <Unauthorized />} />
              <Route path='/qrcode-generator' element={<QRCodeGenerator />} />
              <Route path='/cert-editor-surveyor' element={(surveyor.includes(userRole)) ? <CertEditorSurveyor /> : <Unauthorized />} />
              <Route path='/error-page' element={<ErrorPage />} />
              <Route path='/register' element={(admin.includes(userRole)) ? <Register /> : <Unauthorized />} />
              <Route path='/cert-editor' element={(admin.includes(userRole)) ? <CertEditor /> : <Unauthorized />} />
              <Route path='/cert-editorNew' element={(admin.includes(userRole)) ? <CertEditorNew /> : <Unauthorized />} />
              <Route path='/users' element={(admin.includes(userRole)) ? <UserList /> : <Unauthorized />} />
              <Route path='/userRegsitration' element={(admin.includes(userRole)) ? <UserRegistartion /> : <Unauthorized />} />
              <Route path='/company' element={(admin.includes(userRole)) ? <CompanyTable /> : <Unauthorized />} />
              <Route path='/clientVesselMap' element={(admin.includes(userRole)) ? <ClientVesselMap /> : <Unauthorized />} />
              <Route path='/addcompany' element={(admin.includes(userRole)) ? <AddCompany /> : <Unauthorized />} />
              <Route path='/clientVesselMap/newMap' element={(admin.includes(userRole)) ? <CreateClientVesselMap /> : <Unauthorized />} />
              <Route path='/department' element={(admin.includes(userRole)) ? <DepartmentTable /> : <Unauthorized />} />
              <Route path='/registerdepartment' element={(admin.includes(userRole)) ? <AddDepartment /> : <Unauthorized />} />
              <Route path='/viewdepartment' element={(admin.includes(userRole)) ? <ViewPage /> : <Unauthorized />} />
              <Route path='/viewcompany' element={(admin.includes(userRole)) ? <ViewCompany /> : <Unauthorized />} />
              <Route path='/viewvessel' element={(admin.includes(userRole)) ? <ViewVessel /> : <Unauthorized />} />
              <Route path='/viewuser' element={(admin.includes(userRole)) ? <ViewUser /> : <Unauthorized />} />
              <Route path='/vessel' element={(admin.includes(userRole)) ? <VesselTable /> : <Unauthorized />} />
              <Route path='/addvessel' element={(admin.includes(userRole)) ? <AddVessel /> : <Unauthorized />} />
              <Route path='/item' element={(admin.includes(userRole)) ? <TaskItem /> : <Unauthorized />} />
              <Route path='/itemlist' element={(admin.includes(userRole)) ? <ItemList /> : <Unauthorized />} />
              <Route path='/viewitem' element={(admin.includes(userRole)) ? <ViewItem /> : <Unauthorized />} />
              <Route path='/viewsurveys' element={(projectmanager.includes(userRole)) ? <ViewSurveys /> : <Unauthorized />} />
              <Route path='/viewCertificates' element={(surveyor.includes(userRole)) ? <ViewCertificates /> : <Unauthorized />} />
              <Route path='/requestlist' element={(admin.includes(userRole)) ? <RequestList /> : <Unauthorized />} />
              <Route path='/addrequest' element={(admin.includes(userRole)) ? <AddRequest /> : <Unauthorized />} />
              <Route path='/dashboard' element={(dashboardview.includes(userRole)) ?<MainDashboard /> : <Unauthorized/>} />
              <Route path='/viewrequest' element={(admin.includes(userRole)) ? <ViewReqPage /> : <Unauthorized />} />
              <Route path='/viewmoresurvey' element={(projectmanager.includes(userRole)) ? <ViewMoreSurvey /> : <Unauthorized />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/surveyChecklist" element={<SurveyChecklist />} />
              <Route path="/attachments" element={<Attachments/>} />
              <Route path="/Checklist" element={<Checklist />} />
        

            </Routes>
          </div>
        </main>
        <footer></footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
