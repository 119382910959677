import React, { useState } from 'react';
import axios from 'axios';
import {useLocation, useNavigate} from 'react-router-dom'

function QRCodeGenerator() {
  const [qrCodeImage, setQRCodeImage] = useState('');
  const [content, setContent] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  
  const goback = location.state?.from?.pathname || "/";

 const   fetchQRCodeImage = async(content) =>{
  
//const response = await axios.get(`http://localhost:8081/api/qrcode/generate/${content}`, {
  const response = await axios.get(`http://44.207.146.240/api/qrcode/generate/${content}`, {
      responseType: 'arraybuffer',
    })

    if (response.status === 200) {
      console.log(response.data)
   
      const base64Image = btoa(
        new Uint8Array(response.data)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      const imgSrc = `data:image/png;base64,${base64Image}`;
      setQRCodeImage(imgSrc);
    }
    else {
      throw new Error('Error fetching QR code image:');
    }
      
    
};

const generateQRCode = (event) => {
  event.preventDefault();
  //const content = 'Hello World'; // Replace with the desired content
  fetchQRCodeImage(content);
};

function handleChange(event) {
  event.preventDefault();
  setContent(event.target.value);
  //console.log(companyname)
};

const downloadQRCode = (event) => {
  event.preventDefault();
  if (qrCodeImage) {
    const link = document.createElement('a');
    link.href = qrCodeImage;
    link.download = 'qrcode.png';
    link.click();
  }
};
function handleClose(event) {
  event.preventDefault();
  navigate(goback);
}


return (
  <div id="id01" className="modal">
    <form className="modal-content animate" >
      <label>
          Enter you content for QR code generation:
          <input type="text" value={content} onChange={handleChange} />
        </label>
        <button onClick={(event) => generateQRCode(event)}>Generate QR Code</button>

    {qrCodeImage && (
        <div align = "center">
          <img src={qrCodeImage} alt="QR Code" />
        <button onClick={(event) => downloadQRCode(event)}>Download QR Code</button>

         
        </div>
      )}
       <button className="modal-close-btn" onClick={handleClose}>
          <b>Close</b>
        </button>
   </form> 
  </div>
);

};
export default QRCodeGenerator;
