import React from "react";
import { useState } from "react";
//import axios from 'axios';
import { useNavigate, useLocation } from "react-router";
//import EditorDataContext from '../Context/editorDataProvider';
import Select from "react-select";
import company from '../dataSource.json';
import ENV from "../../config";
import LeftNav from "../../Components/LeftNav/LeftNav";
import Header from "../../Header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import '../../Css/pages.css'
import { Button } from "@mui/material";

const TemplateChooser = () => {
  //const [template, setTemplate] = useState('TEST');


  const companyval = [
    { value: company.company.CompanyId, label: company.company.CompanyName }

  ];
  const demo={ value: company.company.CompanyId, label: "DAKAR" }

    // Initialize state for selected option with the default value set to the first option
    const [selectedOption, setSelectedOption] = useState(companyval[0]); 

    // Initialize state for storing error messages
    const [error, setError] = useState("");

    // Initialize state for storing input text from user
    const [text, setText] = useState("");

  const navigate = useNavigate();
    const location = useLocation();

    // Extract "from" pathname from location state or set default to "/cert-editorNew"
    const from = location.state?.from?.pathname || "/cert-editorNew";

    // Extract "goback" pathname from location state or set default to "/"
  const goback = location.state?.from?.pathname || "/"

    // Function to handle form submission
  const handleSubmit = async (e) => {
      e.preventDefault();  // Function to handle form submission

    // Validate the input for forbidden characters
    const forbiddenChars = /[\/\\|]/;
    if (forbiddenChars.test(text)) {
      setError("Invalid special characters: / \\ | are not allowed.");
        return; // Exit the function if invalid characters are found
    }

      try {
          // Log the input text to the console
          console.log(text);

          // Navigate to the specified route with state data
      navigate(from, { state: { data: text, company: selectedOption.label, flow: "create" } });
      } catch (e) {
          // Log an error message if an exception occurs
      console.log("errored");
      setError("An error occurred while submitting the form.");
          console.log(e);
          // Navigate to the "from" route with replace option
      navigate(from, { replace: true });
    }
  };

    // Function to handle changes in the input field
    function handleChange(event) {
        setText(event.target.value.toUpperCase()); // Update the text state with the new value
        setError(""); // Clear any existing error messages
    }
    // Function to handle closing the form and navigating to a different route
    function handleClose(event) {
        navigate('/list-templates'); // Navigate to the "/list-templates" route
    }

  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "87%" }}>
            <main>
              <div class="path">
                <h2 class="active">
                  Create Template
                </h2>
                <h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                  >
                    <path
                      d="M1 11L4 6L1 1"
                      stroke="#8B8B8B"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </h2>
                <h2 class="inactive" >
                  Editor
                </h2>

              </div>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper" style={{ minHeight: '50vh' }}>

                    <h1 className="heading-survey">Create Template</h1>
                    <label htmlFor="roles" className="heading-template"><b>Company:</b></label>
                    <div>
                      <Select
                        options={companyval} className="select-template"
                        defaultValue={demo}
                        onChange={setSelectedOption}
                        isDisabled={true}
                      />
                    </div>

                    <label className="heading-template">
                      <b>Template Name:</b>
                      <input className="select-template" type="text" value={text} onChange={handleChange} />
                    </label>

                    {error && <p style={{ color: 'red' }}>{error}</p>}

                      <div class="btn-container-multiple"  >
                        <button class="modal-close-btn" onClick={handleClose}>
                          <b>Close</b>
                        </button>
                        <button class="modal-submit-btn" onClick={handleSubmit}>
                          <b>Proceed</b>
                        </button>
                      </div>


                  </div>
                </div>

              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div>
    </div>







  );
};

export default TemplateChooser;
