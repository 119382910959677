

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom'
import '../../../Css/pages.css';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Header from '../../../Header/Header';
import LeftNav from '../../LeftNav/LeftNav';
import axios from 'axios';
import env from '../../../config';



const AddCompany = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Retrieve data from location state if available (for updates)
    const updatedata = location.state?.data;
    const [companyId, setCompanyId] = useState(updatedata?.companyId || '');
    const [cName, setCName] = useState(updatedata?.companyName || '');
    const [email, setEmail] = useState(updatedata?.email || '');
    const [phone, setPhone] = useState(updatedata?.phoneNumber || '');
    const [address, setAddress] = useState(updatedata?.address || '');
    const [website, setWebsite] = useState(updatedata?.website || '');
    const [category, setCategory] = useState(updatedata?.categoryName || '');
    const [codeName, setCodeName] = useState(updatedata?.codeName || '');
    const [locationname, setLocationname] = useState(updatedata?.locationName || '');
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [errors, setErrors] = useState({});  // State to manage form validation errors
    const [errorMessage, setErrorMessage] = useState('');

    const isUpdate = location.state?.isUpdate;  // Flag to determine if we are updating an existing company

    // Function to navigate back to the company list page
    const handleCancel = () => {
        navigate('/company' ,{
            state: {
                search:location.state?.search
            }
        });
    };
    const checkDuplicateCompany = async (name) => {
        try {
            const response = await axios.get(`${env.backend}/api/company/getCompanyByName`, {
                params: { name: name }
            });

            // console.log("Response status:", response.status);
            // console.log("Response data:", response.data);


            return response.data.companyName ? true : false;
        } catch (error) {

            if (error.response && error.response.status === 500) {

                return true;
            }

            console.error("no company with same name", error);
            return false;
        }
    };



    const handleBlur = async () => {
        if (cName) {
            console.log("Checking for duplicate company name:", cName);
            const duplicateExists = await checkDuplicateCompany(cName);

            console.log("Duplicate exists:", duplicateExists);

            if (duplicateExists) {
                setIsDuplicate(true);
                setErrorMessage("Client Name already exists.");
            } else {
                setIsDuplicate(false);
                setErrorMessage("");
            }
        }
    };




    // Validate form inputs before submission
    const validate = async () => {
        const newErrors = {};

        // Check for required fields
        if (!cName) newErrors.cName = "Client Name is required.";
        if (!email) newErrors.email = "Email is required.";
        if (!phone) newErrors.phone = "Phone Number is required.";

        // Check for duplicate company name
        const duplicateExists = await checkDuplicateCompany(cName);
        if (duplicateExists) {
            newErrors.cName = "Client Name already exists.";
            setIsDuplicate(true);
        } else {
            setIsDuplicate(false);
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Function to handle form submission
    const handleSubmit = async () => {
        if (isUpdate) {
            if (!validate()) { 
                return;  
            }
        } else {
            if (!validate() || isDuplicate) { 
                return;  
            }
        }

        // Construct the data object for the API request
        const data = {
            companyName: cName,
            email: email,
            phoneNumber: phone,
            address: address,
            website: website,
            // categoryName: category,
            // codeName: codeName,
            locationName: locationname,
            createdBy: localStorage.getItem("userFullName"),  // Retrieve user info
            createdOn: new Date(),  // Set current date and time
        };

        if (isUpdate) {
            // Add update-specific fields
            data.companyId = companyId;
            data.updatedBy = localStorage.getItem("userFullName");
            data.updatedOn = new Date();

            console.log("Update data", data);

            try {
                // Make an API call to update the existing company
                const response = await axios.put(`${env.backend}/api/company/${companyId}`, data);

                if (response.status === 200) {
                    console.log("Client updated!", response);
                    navigate('/company', {
                        state: {
                            data: {
                                update: true,
                            }
                        }
                    });
                } else {
                    console.error("Error updating Client");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        } else {
            console.log("Save data", data);

            try {
                // Make an API call to create a new company
                const response = await axios.post(`${env.backend}/api/company`, data);

                if (response.status === 201) {
                    console.log("Client created!", response);
                    // Navigate to the company list page with a success flag
                    navigate('/company', {
                        state: {
                            data: {
                                create: true,
                            }
                        }
                    });
                } else {
                    console.error("Error creating Client");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    // Function to handle phone number input changes and ensure it only contains numbers and +
    const handlePhoneChange = (e) => {
        const value = e.target.value;
        // Regular expression to allow only numbers and +
        const cleanedValue = value.replace(/[^0-9+]/g, '');
        setPhone(cleanedValue);
    };

    // Function to handle email input changes and validate email format
    const handleEmailChange = (e) => {
        const value = e.target.value;
        // Regular expression to validate email format
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        setEmail(value);

        // Check if email matches the pattern and update errors state accordingly
        if (!emailPattern.test(value)) {
            setErrors(prevErrors => ({ ...prevErrors, email: 'Invalid email format. ' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, email: '' }));
        }
    };



    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper">
                                        <div >
                                            <h1 class="titlereg ph-view">{isUpdate ? "Update Client" : "Client Registration"}</h1>
                                            <div>
                                                <form class="formregister">

                                                    {/* <p class="messagereg">Signup now and get full access to our app. </p> */}

                                                    <label>
                                                    <span className="vessel-label">Client Name</span>
                                                        <div className="input-container">
                                                            <span className="required-star">*</span>
                                                            <input
                                                                id="Cname"
                                                                placeholder="Client Name"
                                                                type="text"
                                                                className="inputreg"
                                                                autoComplete="off"
                                                                onChange={(e) => { setCName(e.target.value)}}
                                                                onBlur={handleBlur}
                                                                value={cName}
                                                                required
                                                                disabled={isUpdate} 
                                                            />
                                                            {isDuplicate && <div style={{ color: 'red' }}>{errorMessage}</div>}
                                                        </div>
                                                    </label>






                                                    <label>
                                                    <span className="vessel-label">Email</span>
                                                        <div className="input-container">
                                                            <span className="required-star">*</span>
                                                            <input id='email'
                                                                placeholder="Email" type="email"
                                                                autoComplete='off'
                                                                onChange={handleEmailChange}
                                                                value={email}
                                                                required
                                                                className={`inputreg ${email ? 'no-border' : 'with-border'}`}
                                                            />

                                                        </div>
                                                    </label>
                                                    <label htmlFor="number">
                                                    <span className="vessel-label">Number</span>
                                                        <div className="input-container">
                                                            <span className="required-star">*</span>
                                                            <input
                                                                id="phonenumber"
                                                                placeholder="Phone Number"
                                                                type="tel" // Use type="tel" for phone number input
                                                                className="inputreg"
                                                                autoComplete="off"
                                                                onChange={handlePhoneChange} // Update phone state
                                                                value={phone} // Bind value to phone state
                                                                required
                                                            />

                                                        </div>
                                                    </label>

                                                    <label>
                                                    <span className="vessel-label">Address</span>
                                                        <input id='address'
                                                            placeholder="Address" type="text" class="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => { setAddress(e.target.value) }}
                                                            value={address}
                                                            required
                                                        />

                                                    </label>
                                                    <label>
                                                    <span className="vessel-label">Website</span>
                                                        <input id='website'
                                                            placeholder="Website" type="website" class="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => { setWebsite(e.target.value) }}
                                                            value={website}
                                                            required
                                                        />

                                                    </label>

                                                    {/* <FormControl fullWidth>

                                                        <InputLabel htmlFor='locate' id="select-label" sx={{
                                                            "&.MuiInputLabel-root": {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI Historic'
                                                            },
                                                            "&.MuiInputLabel-shrink": {
                                                                marginTop: '0px'
                                                            }
                                                        }} >Location</InputLabel>

                                                        <Select sx={{ borderRadius: "10px", "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                                            labelId="select-label"
                                                            id="locate"
                                                            value={locationname}
                                                            label="Locate"
                                                            onChange={(e) => setLocationname(e.target.value)}
                                                        >
                                                            <MenuItem value='' disabled selected className="dropdown-list">Select</MenuItem >
                                                            <MenuItem value='HEADOFFICE' className="dropdown-list">HEADOFFICE</MenuItem >
                                                            <MenuItem value='KOCHI' className="dropdown-list">KOCHI</MenuItem >
                                                            <MenuItem value='DUBAI' className="dropdown-list">DUBAI</MenuItem >
                                                        </Select>
                                                    </FormControl> */}
                                                </form>
                                            </div>
                                        </div>
                                        <div class="btn-container-multiple">
                                            <button class="modal-submit-btn" onClick={handleSubmit} ><b>{isUpdate ? "Update" : "Save"}</b></button>
                                            <button style={{ marginRight: "23px" }} className="modal-close-btn" onClick={handleCancel}><b>Close</b></button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>


    )
}

export default AddCompany
