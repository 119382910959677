import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'
import ENV from "../config";
import { useContext } from "react";
import AuthContext from "../Context/authProvider";
import handleToken from '../Components/TokenHandler';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Plugins } from '@capacitor/core';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import Header from '../Header/Header';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";



const FinalizedPdfPreview = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [comment, setComment] = useState('Rejected');
  const { auth } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const certeditorsurveyor = location.state?.from?.pathname || "/cert-editor-surveyor";
  const companyname = location.state.company;
  const surveyid = location.state.surveyid;
  const surveycertificateid = location.state.surveycertificateid;
  const surveycertificatename = location.state.surveycertificatename;
  const surveyname = location.state.surveyname;
  const vesselid = location.state.vesselid;
  const vesselname = location.state.vesselname;
  const countryName = location.state?.countryName;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCommentProvided, setIsCommentProvided] = useState(true);
  const flow = location.state.flow;
  const status = location.state.status ?? '';
  const surveys = location.state?.survey;
  const prevFlow = location.state?.prevFlow;
  const totemplatelist = location.state?.from?.pathname || "/surveyor-dashboard"
  const [templateNames, setTemplateName] = useState(null)


  // URLs for API endpoints
  const APPROVE_SERVICE_URL = ENV.backend + '/api/surveyor/approve-certificate';
  const REJECT_SERVICE_URL = ENV.backend + '/api/surveyor/reject-certificate';

  useEffect(() => {
    // handleToken();
  })

  useEffect(() => {
    // Effect for fetching the PDF URL
    const fetchPdfUrl = async () => {
      try {
        console.log(surveycertificatename, companyname, surveyid, surveyname, flow);

        // Make API request to fetch the PDF preview
        const response = await axios.get(ENV.backend + '/api/surveyor/savedpdfpreview', {
          params: {
            certificateName: surveycertificatename,
            companyName: companyname,
            surveyId: surveyid,
            surveyName: surveyname,
            flow: flow
          },
          responseType: 'blob',
        }

        );

        if (response.status === 200) {
          console.log("status ok")
          console.log(response)
          const blob = response.data;

          // Handle PDF saving if on a native platform
          if (Capacitor.isNativePlatform()) {
            savePdfToFile(blob);
            console.log("Insode isnative platform");
          }

          // Create a URL object for the PDF and set it in state
          const url = URL.createObjectURL(blob);
          console.log(url)
          setPdfUrl(url);
        } else {
          // Handle the error response
          console.log("error")
        }
      } catch (error) {
        // Handle the fetch error
      }
    };

    fetchPdfUrl();
  }, [surveycertificatename, companyname, surveyid, surveyname, flow]);


  // Function to save PDF as a file on native platforms
  const savePdfToFile = async (blob) => {
    // Convert the blob to a base64 string
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64String = reader.result.split(',')[1]; // Remove the data URL prefix

      // Save the PDF file locally
      const filePath = 'downloaded.pdf';
      const directory = Directory.Documents;

      await Filesystem.writeFile({
        path: filePath,
        data: base64String,
        directory,
      });

      // Open the PDF using the default viewer
      openPdfFile();
    };
  };

  // Function to open the saved PDF file
  const openPdfFile = async () => {
    try {
      const filePathOld = "file:///storage/emulated/0/Documents/downloaded.pdf";
      await FileOpener.openFile({
        path: filePathOld,
      });
    } catch (error) {
      console.error('Error opening PDF:', error);
    }
  };

  // Handle approval action
  const handleApprove = () => {
    let actionSubmittedBy = "";

    // Determine who submitted the action (useful for audit trails)
    if (localStorage.getItem("userFullName") !== "undefined") {
      actionSubmittedBy = localStorage.getItem("userFullName");
    } else{
      actionSubmittedBy = '';
    }

    // Data to send in the approval request
    const data = {
      companyName: companyname,
      templateName: surveycertificatename,
      surveyCertificateName: surveycertificatename.replace(".pdf", ""),
      surveyCertificateId: surveycertificateid,
      surveyName: surveyname,
      surveyId: surveyid,
      updatedBy: localStorage.getItem("userFullName"),
      updatedOn: new Date(),
      actionSubmittedBy: actionSubmittedBy,
      actionSubmittedOn: new Date()
    };
    console.log(data)

    // Make POST request to approve the certificate
    const response = axios.post(APPROVE_SERVICE_URL, data, {
      headers: {
        //	'Content-Type': 'text/html',
      }

    })
    handleClose();

  };

const handleReject = () => {
    setIsModalOpen(true); // Open the modal
};

// Function to close the rejection modal
const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
};

  // Handle rejection action
  const handleSubmitReject = () => {
    let actionSubmittedBy = "";

    // Determine who submitted the action (useful for audit trails)
    if (localStorage.getItem("userFullName") !== "undefined") {
      actionSubmittedBy = localStorage.getItem("userFullName");
    } else{
      actionSubmittedBy = '';
    }

    // Data to send in the rejection request
    const data = {
      companyName: companyname,
      templateName: surveycertificatename,
      surveyCertificateName: surveycertificatename.replace(".pdf", ""),
      surveyCertificateId: surveycertificateid,
      surveyName: surveyname,
      surveyId: surveyid,
      comment: comment,
      updatedBy: localStorage.getItem("userFullName"),
      updatedOn: new Date(),
      actionSubmittedBy: actionSubmittedBy,
      actionSubmittedOn: new Date()
    };
    console.log(data)
    const response = axios.post(REJECT_SERVICE_URL, data, {
      headers: {
        //	'Content-Type': 'text/html',
      }
    })
    setIsModalOpen(false);
    handleClose();
  };

  // Handle comment change in the rejection input
  const handleCommentChange = (event) => {
    setComment(event.target.value);
    setIsCommentProvided(event.target.value.trim() !== '');
    console.log(comment)
  };

  // Handle the close button click
  const handleClose = () => {

    if (flow === "no-back" || flow === "SurveyorCompleted") {
      // Navigate back to the certificates view if flow is "no-back"
      navigate('/viewCertificates', {
        state: {
          status: status,
          survey: surveys,
          flow: prevFlow,
          filterOptions: location.state?.filterOptions
        },
      });
    } 
    else if (flow === "Approval") {
      // Navigate back to the certificates view if flow is "no-back"
      navigate('/viewCertificates', {
        state: {
          status: status,
                survey: surveys,
                surveyid:surveyid,
                flow: flow,
                filterOptions: location.state?.filterOptions
        },
      });
    }
    else {
      // Navigate to the editor or previous page
      navigate(certeditorsurveyor, {
        state: {
          surveycertificateid: surveycertificateid,
          surveycertificatename: surveycertificatename.replace(".pdf", ".html"),
          company: companyname,
          surveyname: surveyname,
          surveyid: surveyid,
          vesselid: vesselid,
          vesselname: vesselname,
          countryName: countryName,
          // flagpath: flagPath,
          // status:status,
          status: status,
          survey: surveys,
          flow: prevFlow,
          filterOptions: location.state?.filterOptions
        },
      });
    }
  };




  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid container-fluid-ex">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          {/* <LeftNav /> */}
          <TabPanel style={{ width: "100%", marginRight: "2%", marginLeft: "2%" }}>
            <main>
              <div>
                <div class="active-heading" >
                  Template Name : {surveycertificatename || 'Editor'}</div>
              </div>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper " style={{ margin: "0", maxHeight: '85vh' }}>
                    <div>
                      <div className="editor-container">

                        {pdfUrl ? (
                          <div style={{ width: '100%' }}>
                            <embed src={pdfUrl} title="PDF Preview" style={{ width: '100%', height: '79vh', border: 'none' }} />

                          </div>
                        ) : (
                          <p>Loading...</p>
                        )}
                      </div>
                      <div class="btn-container-multiple" style={{ marginTop: '0px' }}>
                        
                        <button style={{ height: '28.5px' }} className="modal-close-btn" onClick={handleClose}><div style={{ marginTop: '-7.5px' }}>{flow === "no-back" ? "Close" : "Back"}</div></button>
                        {flow === "Approval" && (
                          <button style={{ height: '28.5px' }} className="modal-reject-btn" onClick={handleReject}><div style={{ marginTop: '-7.5px' }}>Reject</div></button>
                        )}
                        {flow === "Approval" && (
                          <button style={{ height: '28.5px' }} className="modal-submit-btn" onClick={handleApprove}><div style={{ marginTop: '-7.5px' }}>Approve</div></button>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div>
      {isModalOpen && (
                <div className="modal-popup">
                    <div className="modal-content-popup">
                        <h3 style={{ textAlign: 'left' }}>Rejection Reason:</h3>
                        <textarea
                            id="comment"
                            value={comment} className="select-template"
                            placeholder='Provide a reason to reject.'
                            onChange={handleCommentChange}
                            rows={3}
                            cols={50}
                            style={{ width: "100%", height: '19vh' }}
                        ></textarea>
                        <div className="modal-buttons-popup">
                            <button onClick={handleSubmitReject}><div style={{ marginTop: '-7.5px' }}><b>Submit</b></div></button>
                            <button onClick={handleModalClose}><div style={{ marginTop: '-7.5px' }}><b>Cancel</b></div></button>
                        </div>
                    </div>
                </div>
            )}
    </div >
  );
};

export default FinalizedPdfPreview;