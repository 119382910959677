import React, { useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from '../../Header/Header'
import LeftNav from '../../Components/LeftNav/LeftNav';

function LandingPage() {
    useEffect(() => {
        // Check if user is not logged in
        if (!localStorage.getItem('accessToken')&&!window.location.href.includes('/signin')) {
          // If not logged in, navigate to the signin page
          window.location.href = '/signin'; // Assign the URL to window.location.href
        }
      }, []);
    return (
        <div>
            <Header />
            <div className="container-fluid">
                <Tabs className="vertical-tabs">
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                   <LeftNav/>
                    <TabPanel>
                        <div className="card-listing-section">
                            {/* <h2 className="active-menu ml-3">Listings</h2> */}
                            <div className="ml-3">
                               
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>


        </div>
    )
}

export default LandingPage