import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ENV from "../../config";
import Header from "../../Header/Header";
import LeftNav from "../../Components/LeftNav/LeftNav";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import handleToken from "../../Components/TokenHandler";
import LoaderDashboard from "../../Components/LoaderDashboard";
import {
  FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Button,
  Tooltip,
  IconButton, Grid
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import env from "../../config";

// Define the ApproverDashboard component
function ApproverDashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const initialcompany = location.state?.filterOptions?.company || [];
  const [selectedCompanies, setSelectedCompanies] = useState(initialcompany);
  const initialstatus = location.state?.filterOptions?.status || [];
  const [selectedStatuses, setSelectedStatuses] = useState(initialstatus);
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  const initialCountries = location.state?.filterOptions?.country || [];
  const [selectedCountries, setSelectedCountries] = useState(initialCountries);
  const initialvessel = location.state?.filterOptions?.vessel || [];
  const [selectedVessels, setSelectedVessels] = useState(initialvessel);
  const [statusSelectionOrder, setStatusSelectionOrder] = useState([]);
  const countryNames = Array.from(new Set(surveys.map(survey => survey.countryName)));
  const vesselNames = [...new Set(surveys.map(survey => survey.vesselName))];
  const [availableFlags, setAvailableFlags] = useState([]);
  const [availableVessels, setAvailableVessels] = useState([]);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const goback = location.state?.from?.pathname || "/";

  // Fetch surveys on component mount
  useEffect(() => {
    fetchSurveys();
    // handleToken();
  }, []);

  // Update available options and filter surveys when filters or surveys change
  useEffect(() => {
    updateAvailableOptions();
    filterSurveys();
  }, [surveys, selectedCompanies, selectedStatuses, selectedCountries, selectedVessels]);

  // Navigate back to the previous page
  const handleClose = () => {
    navigate(goback);
  };

  // Fetch surveys from the backend
  const fetchSurveys = async () => {
    try {
      let response = []
      if (localStorage.getItem('role') === "Admin" || localStorage.getItem('role') === "CertPro Admin" || localStorage.getItem('role') === "Project Manager") {
        response = await axios.get(env.backend + "/api/survey");
      }
      else {
        const userId = localStorage.getItem('userFullName')
        response = await axios.get(env.backend+"/api/survey/getSurveys/"+userId);
      }
      // const response = await axios.get(ENV.backend + "/api/survey");
      setSurveys(Object.values(response.data)); // Set surveys state with fetched data
      setLoading(false);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      setLoading(false);
    }
  };

  // Get the most recent creation date from the surveys
  const getLastCreatedDate = (surveys) => {
    const createdDates = surveys.flatMap((survey) =>
      survey.surveyCertificateList.map((item) => new Date(item.createdOn))
    );
    if (createdDates.length === 0) {
      return 0;
    }
    return Math.max(...createdDates);
  };

  // Map surveys by company
  const surveyMap = new Map();
  surveys.forEach((survey) => {
    const { companyName, ...rest } = survey;
    if (surveyMap.has(companyName)) {
      surveyMap.get(companyName).push({ companyName, ...rest });
    } else {
      surveyMap.set(companyName, [{ companyName, ...rest }]);
    }
  });

  // Extract unique company names
  const companyNames = Array.from(surveyMap.keys());
  const statusOptions = ["Completed", "Approved"]; // Status options available for filtering

  // Update available options based on selected filters
  const updateAvailableOptions = () => {
    let filteredFlags = [];
    let filteredVessels = [];
    let filteredStatuses = [];

    surveys.forEach(survey => {
      if (selectedCompanies.includes(survey.companyName) || selectedCompanies.length === 0) {
        if (!filteredFlags.includes(survey.countryName)) {
          filteredFlags.push(survey.countryName);
        }
        if (!filteredVessels.includes(survey.vesselName)) {
          filteredVessels.push(survey.vesselName);
        }
        survey.surveyCertificateList.forEach(cert => {
          if (!filteredStatuses.includes(cert.surveyCertificateStatus)) {
            filteredStatuses.push(cert.surveyCertificateStatus);
          }
        });
      }
    });

    // Sort available options alphabetically
    setAvailableFlags(filteredFlags.sort((a, b) => String(a).localeCompare(String(b))));
    setAvailableVessels(filteredVessels.sort((a, b) => String(a).localeCompare(String(b))));
    setAvailableStatuses(filteredStatuses.sort((a, b) => String(a).localeCompare(String(b))));
  };
  // Event handler for changing selected companies
  const handleCompanyChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  // Event handler for changing selected countries
  const handleCountryChange = (event) => {
    setSelectedCountries(event.target.value);
  };

  // Event handler for changing selected vessels
  const handleVesselChange = (event) => {
    setSelectedVessels(event.target.value);
  };

  // Event handler for changing selected statuses
  const handleStatusChange = (event) => {
    const value = event.target.value;
    setSelectedStatuses(value);

    // Update the order of selected statuses
    const newOrder = value.reduce((acc, status) => {
      if (!acc.includes(status)) {
        acc.push(status);
      }
      return acc;
    }, []);
    setStatusSelectionOrder(newOrder);
  };

  // Filter surveys based on selected criteria
  const filterSurveys = () => {
    let surveys = [];

    // Collect surveys based on selected companies
    if (selectedCompanies.length > 0) {
      selectedCompanies.forEach(company => {
        if (surveyMap.has(company)) {
          surveys.push(...surveyMap.get(company));
        }
      });
    } else {
      surveyMap.forEach((value) => {
        surveys.push(...value);
      });
    }
    // Filter surveys by selected statuses
    if (selectedStatuses.length > 0) {
      surveys = surveys.filter(survey =>
        survey.surveyCertificateList.some(cert => {
          const isRejected = selectedStatuses.includes("Rejected") && cert.surveyCertificateStatus === "In-Progress" && cert.comment !== null;
          const isSelectedStatus = selectedStatuses.includes(cert.surveyCertificateStatus);
          return isRejected || isSelectedStatus;
        })
      );
    }
    // Filter surveys by selected countries
    if (selectedCountries.length > 0) {
      surveys = surveys.filter(survey =>
        selectedCountries.includes(survey.countryName)
      );
    }
    // Filter surveys by selected vessels
    if (selectedVessels.length > 0) {
      surveys = surveys.filter(survey =>
        selectedVessels.includes(survey.vesselName)
      );
    }
    // Sort surveys by creation date in descending order
    surveys = surveys.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

    // Filter certificates by status (Approved or Completed)
      surveys.forEach(survey => {
        survey.surveyCertificateList = survey.surveyCertificateList.filter(cert => {
          if (localStorage.getItem('role') === "CertPro Admin") {
            return cert.surveyCertificateStatus === "Approved"
          }
          else {
            return cert.surveyCertificateStatus === "Approved" ||
              cert.surveyCertificateStatus === "Completed"
          }
        });
      });
    

    // Transform surveys to include the calculated survey status
    let transformedSurveys = surveys.map(survey => {
      const statusMap = {
        Approved: 0,
        Completed: 0
      };

      // Count each certificate's status
      survey.surveyCertificateList.forEach(cert => {
        if (statusMap.hasOwnProperty(cert.surveyCertificateStatus)) {
          statusMap[cert.surveyCertificateStatus]++;
        }
      });

      // Determine the overall survey status
      let surveyStatus;
      if (statusMap.Approved === survey.surveyCertificateList.length) {
        surveyStatus = "Approved";
      } else if (statusMap.Completed === survey.surveyCertificateList.length) {
        surveyStatus = "Completed";
      } else if (statusMap.Approved + statusMap.Completed === survey.surveyCertificateList.length) {
        surveyStatus = "Completed";
      }

      return {
        ...survey,
        surveyStatus, // Add the determined survey status
        surveyCertificateList: survey.surveyCertificateList // Already filtered above
      };
    });


    // Remove null entries (surveys with only "Approved" certificates)
    transformedSurveys = transformedSurveys.filter(survey => survey !== null);

    // Remove duplicates and keep the one entry per survey
    const uniqueSurveysSet = new Set();
    const uniqueSurveys = transformedSurveys.filter(survey => {
      const key = `${survey.surveyId}-${survey.surveyName}-${survey.surveyStatus}`;
      if (uniqueSurveysSet.has(key)) {
        return false;
      }
      uniqueSurveysSet.add(key);
      return true;
    });

    // Sort the unique surveys based on status and creation date
    const sortedSurveys = uniqueSurveys.sort((a, b) => {
      const statusOrderComparison = statusSelectionOrder.indexOf(b.surveyStatus) - statusSelectionOrder.indexOf(a.surveyStatus);
      if (statusOrderComparison !== 0) {
        return statusOrderComparison;
      }
      // Then sort by creation date in descending order
      return new Date(b.updatedOn) - new Date(a.updatedOn);
    });

    console.log("Filtered surveys", sortedSurveys);
    setFilteredSurveys(sortedSurveys);
  };


  // Filter company names based on the survey data
  const filteredCompanyNames = companyNames.filter(companyName => {
    return surveys.some(survey => {
      return survey.companyName === companyName &&
        survey.surveyCertificateList.some(cert => cert.surveyCertificateStatus === 'Completed' || cert.surveyCertificateStatus === 'Approved');
    });
  });

  // Filter surveys to show based on selected status
  const filteredSurveysToShow = filteredSurveys.filter(survey =>
    survey.surveyCertificateList.some(cert =>
      (cert.surveyCertificateStatus === 'Completed' || cert.surveyCertificateStatus === 'Approved')
    )
  );


  // Get filtered certificates by status
  const getFilteredCertificates = (certificates) => {
    return certificates.filter(cert => cert.surveyCertificateStatus === 'Completed' || cert.surveyCertificateStatus === 'Approved');
  };

  // Navigate to view certificates page
  const handleViewClick = (survey) => {
    navigate(`/viewcertificates`, {
      state: {
        survey: survey,
        flow: "Approval",
        status: survey.surveyStatus,
        filterOptions: {
          company: selectedCompanies,
          status: selectedStatuses,
          country: selectedCountries,
          vessel: selectedVessels
        }
      }
    });
  };

  // Format date to DD/MM/YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "87%" }}>
            <main>
              <div className="container-fluid-buyer">
                <div className="card-listing-section">
                  <div className="right-wrapper">
                    <div>
                      <h1><div className="header-surveyor">Approver Dashboard</div></h1>
                    </div>
                    {
                      loading ? (<LoaderDashboard />) : (
                        <>
                          <Paper>
                            <div className="search-bar-container" style={{ marginTop: '-23px' }}>
                              <Grid container spacing={2} style={{ display: 'contents' }}>

                                <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                  <FormControl fullWidth>
                                    <InputLabel sx={{
                                      "&.MuiInputLabel-root": {
                                        marginTop: '-11px',
                                        fontFamily: 'Segoe UI Historic'
                                      },
                                      "&.MuiInputLabel-shrink": {
                                        marginTop: '0px'
                                      }
                                    }}>Companies</InputLabel>
                                    <Select
                                      sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                      multiple
                                      value={selectedCompanies}
                                      onChange={handleCompanyChange}
                                      input={<OutlinedInput label="Companies" />}
                                      renderValue={(selected) => selected.join(', ')}
                                    >
                                      {Array.from(surveyMap.keys()).sort((a, b) => a.localeCompare(b)).map((name) => (
                                        <MenuItem key={name} value={name} sx={{
                                          paddingTop: '0px',
                                          paddingBottom: '0px',
                                          paddingLeft: '0px',
                                          paddingRight: '2px',
                                          fontFamily: 'Segoe UI Historic'
                                        }}>
                                          <Checkbox checked={selectedCompanies.indexOf(name) > -1} />
                                          <ListItemText primary={name} sx={{
                                            '& .MuiListItemText-primary': {
                                              fontSize: '14px',
                                              fontFamily: 'Segoe UI Historic'
                                            }
                                          }} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                  <FormControl fullWidth>
                                    <InputLabel sx={{
                                      "&.MuiInputLabel-root": {
                                        marginTop: '-11px',
                                        fontFamily: 'Segoe UI Historic'
                                      },
                                      "&.MuiInputLabel-shrink": {
                                        marginTop: '0px'
                                      }
                                    }}>Flag</InputLabel>
                                    <Select
                                      sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                      multiple
                                      value={selectedCountries}
                                      onChange={handleCountryChange}
                                      input={<OutlinedInput label="Countries" />}
                                      renderValue={(selected) => selected.join(', ')}
                                    >
                                      {availableFlags.map(name => (
                                        <MenuItem key={name} value={name} sx={{
                                          paddingTop: '0px',
                                          paddingBottom: '0px',
                                          paddingLeft: '0px',
                                          paddingRight: '2px',
                                          fontFamily: 'Segoe UI Historic'
                                        }}>
                                          <Checkbox checked={selectedCountries.indexOf(name) > -1} />
                                          <ListItemText primary={name} sx={{
                                            '& .MuiListItemText-primary': {
                                              fontSize: '14px',
                                              fontFamily: 'Segoe UI Historic'
                                            }
                                          }} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                  <FormControl fullWidth>
                                    <InputLabel sx={{
                                      "&.MuiInputLabel-root": {
                                        marginTop: '-11px',
                                        fontFamily: 'Segoe UI Historic'
                                      },
                                      "&.MuiInputLabel-shrink": {
                                        marginTop: '0px'
                                      }
                                    }}>Vessel Name</InputLabel>
                                    <Select
                                      sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                      multiple
                                      value={selectedVessels}
                                      onChange={handleVesselChange}
                                      input={<OutlinedInput label="Vessel Name" />}
                                      renderValue={(selected) => selected.join(', ')}
                                    >
                                      {availableVessels
                                        .slice()
                                        .filter(i => i)
                                        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                                        .map((id) => (
                                          <MenuItem key={id} value={id} sx={{
                                            paddingTop: '0px',
                                            paddingBottom: '0px',
                                            paddingLeft: '0px',
                                            paddingRight: '2px',
                                            fontFamily: 'Segoe UI Historic'
                                          }}>
                                            <Checkbox checked={selectedVessels.includes(id)} />
                                            <ListItemText primary={id} sx={{
                                              '& .MuiListItemText-primary': {
                                                fontSize: '14px',
                                                fontFamily: 'Segoe UI Historic'
                                              }
                                            }} />
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                  <FormControl fullWidth>
                                    <InputLabel sx={{
                                      "&.MuiInputLabel-root": {
                                        marginTop: '-11px',
                                        fontFamily: 'Segoe UI Historic'
                                      },
                                      "&.MuiInputLabel-shrink": {
                                        marginTop: '0px'
                                      }
                                    }}>Status</InputLabel>
                                    <Select
                                      sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                      multiple
                                      value={selectedStatuses}
                                      onChange={handleStatusChange}
                                      input={<OutlinedInput label="Status" />}
                                      renderValue={(selected) => selected.join(', ')}
                                    >
                                      {statusOptions.map((status) => (
                                        <MenuItem key={status} value={status} sx={{
                                          paddingTop: '0px',
                                          paddingBottom: '0px',
                                          paddingLeft: '0px',
                                          paddingRight: '2px',
                                          fontFamily: 'Segoe UI Historic'
                                        }}>
                                          <Checkbox checked={selectedStatuses.indexOf(status) > -1} />
                                          <ListItemText primary={status} sx={{
                                            '& .MuiListItemText-primary': {
                                              fontSize: '14px',
                                              fontFamily: 'Segoe UI Historic'
                                            }
                                          }} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </div>
                            <TableContainer component={Paper} sx={{ maxHeight: '77vh' }} className="table-container">
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className='table-head-new' style={{ width: "20%" }}>Client Name</TableCell>
                                    <TableCell className='table-head-new' style={{ width: "20%" }}>Vessel Name</TableCell>
                                    <TableCell className='table-head-new' style={{ width: "20%" }}>Survey Id</TableCell>
                                    <TableCell className='table-head-new' style={{ width: "20%" }}>Survey Name</TableCell>
                                    <TableCell className='table-head-new' style={{ width: "8%" }}>Status</TableCell>
                                    <TableCell className='table-head-new' style={{ width: "10%" }}>Created By</TableCell>
                                    <TableCell className='table-head-new' style={{ width: "10%" }}>Created Date</TableCell>
                                    <TableCell className='table-head-new' style={{ width: "5%" }}>Actions</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {filteredSurveysToShow.map((survey, index) => (
                                    <TableRow key={index}>
                                      <TableCell className="table-cell-survey">{survey.companyName}</TableCell>
                                      <TableCell className="table-cell-survey">{survey.vesselName}</TableCell>
                                      <TableCell className="table-cell-survey">{survey.surveyId.length > 15 ? `${survey.surveyId.slice(0, 15).toUpperCase()}..` : survey.surveyId.toUpperCase()}</TableCell>
                                      <TableCell className="table-cell-survey">{survey.surveyName}</TableCell>
                                      <TableCell className="table-cell-survey">{survey.surveyStatus}</TableCell>
                                      <TableCell className="table-cell-survey">{survey.createdBy}</TableCell>
                                      <TableCell className='table-cell-survey'>{survey.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(survey.createdOn)) : ''}</TableCell>
                                      <TableCell className="table-cell-survey">
                                        <div style={{ width: '35%', display: 'flex', alignItems: 'center' }}>
                                          <Tooltip title={getFilteredCertificates(survey.surveyCertificateList)[0]?.surveyCertificateStatus === 'Approved' ? "View Certificates" : "View Templates"}>
                                            <IconButton sx={{ padding: '2px' }} onClick={() => handleViewClick(survey)}>
                                              <VisibilityIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </div>

                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Paper>
                        </>
                      )
                    }
                  </div>
                </div>
              </div>

            </main>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default ApproverDashboard;
