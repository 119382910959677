import axios from 'axios';
import ENV from "../config";
const getS3ObjectsPdf =  async(param1, param2) => {
  try {
    const response = await axios.get(ENV.backend+ '/api/list-cms-bucket-contents-pdf', {
      params: {
        templateName: param1,
        companyName: param2,
      },
    });

    if (response.status === 200) {
      console.log(response.data)
      return response.data;
    } else {
      throw new Error('Failed to retrieve S3 objects');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getS3ObjectsPdf;
