import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Button } from '@mui/material';
import { Search, Add, Edit, Visibility } from '@mui/icons-material';
import './UserList.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../../Header/Header';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import LeftNav from '../../LeftNav/LeftNav';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';


const dummyData = [
    { id: 1, name: 'John Doe', email: 'john.doe@example.com' },
    { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com' },
    { id: 3, name: 'Alice Johnson', email: 'alice.johnson@example.com' },
    { id: 4, name: 'Chris Lee', email: 'chris.lee@example.com' },
    { id: 5, name: 'David Brown', email: 'david.brown@example.com' },
    { id: 6, name: 'Emily Davis', email: 'emily.davis@example.com' },
    { id: 7, name: 'Michael Wilson', email: 'michael.wilson@example.com' },
    { id: 8, name: 'Sarah Taylor', email: 'sarah.taylor@example.com' },
    { id: 9, name: 'Daniel Anderson', email: 'daniel.anderson@example.com' },
    { id: 10, name: 'Laura Martinez', email: 'laura.martinez@example.com' },
];

const UserTable = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const filteredData = dummyData.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase())
    );

    const handleCreateNewUser = () => {
        navigate('/userRegsitration');
    };

    const handleViewUser = (id) => {
        navigate('/viewuser');
    };

    const handleEditUser = (id) => {
    };

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav/>
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper">
                                        <div >
                                            <h1 class="titlereg" style={{ marginLeft: '-21px', fontSize: '26px' }}> Users</h1>
                                            <Paper>
                                                <div className="search-bar-container" style={{ marginTop: '-23px' }}>
                                                    <TextField
                                                        className="search-bar"
                                                        label="Search"
                                                        variant="outlined"
                                                        value={search}
                                                        onChange={handleSearchChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <Search />
                                                            ),
                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI Historic',
                                                            },
                                                            '& .MuiInputLabel-shrink': {
                                                                marginTop: '0px',
                                                            },
                                                            '& .MuiOutlinedInput-input': {
                                                                height: "25px",
                                                                margin: '4px',
                                                                fontFamily: 'Segoe UI Historic',
                                                            }
                                                        }}
                                                    />
                                                    
                                                    <AddToPhotosIcon sx={{ fontSize: "2rem", color: "green", cursor: "pointer" }} onClick={handleCreateNewUser} />
                                                </div>
                                                
                                                <div >
    <TableContainer className="table-container">
        <Table>
            <TableHead className="table-header">
                <TableRow>
                    <TableCell className='table-head-new' style={{ width: "28%" }}>Name</TableCell>
                    <TableCell className='table-head-new' style={{ width: "28%" }}>Email</TableCell>
                    <TableCell className='table-head-new' style={{ width: "29%" }}>Role</TableCell>
                    <TableCell className='table-head-new' style={{ width: "15%" }}>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className="table-body">
                {filteredData.map((row) => (
                    <TableRow key={row.id}>
                        <TableCell className='table-cell-survey'>{row.name}</TableCell>
                        <TableCell className='table-cell-survey'>{row.email}</TableCell>
                        <TableCell className='table-cell-survey'>Project Manager</TableCell>
                        <TableCell className='table-cell-survey'>
                            <div className="action-buttons">
                                <IconButton sx={{ padding: '2px' }}  className="action-button" onClick={() => handleViewUser(row.id)}>
                                    <Visibility />
                                </IconButton>
                                <IconButton sx={{ padding: '2px' }}  className="action-button" onClick={() => handleEditUser(row.id)}>
                                    <Edit />
                                </IconButton>
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
</div>
                                            </Paper>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>

    );
};

export default UserTable;
