import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton } from '@mui/material';
import { Search, Visibility, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Header from '../../../Header/Header';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import LeftNav from '../../LeftNav/LeftNav';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import './RequestList.css';

const dummyData = [
    { id: 1, name: 'John Doe', vessel: 'Vessel A', vesselType: 'Type 1', surveyType: 'Survey X', CreatedOn: '2024-01-15', CreatedBy: 'Admin A' },
    { id: 2, name: 'Jane Smith', vessel: 'Vessel B', vesselType: 'Type 2', surveyType: 'Survey Y', CreatedOn: '2024-02-20', CreatedBy: 'Admin B' },
    { id: 3, name: 'Alice Johnson', vessel: 'Vessel C', vesselType: 'Type 1', surveyType: 'Survey Z', CreatedOn: '2024-03-05', CreatedBy: 'Admin C' },
    { id: 4, name: 'Chris Lee', vessel: 'Vessel D', vesselType: 'Type 3', surveyType: 'Survey X', CreatedOn: '2024-01-30', CreatedBy: 'Admin D' },
    { id: 5, name: 'David Brown', vessel: 'Vessel E', vesselType: 'Type 2', surveyType: 'Survey Y', CreatedOn: '2024-02-14', CreatedBy: 'Admin E' },
    { id: 6, name: 'Emily Davis', vessel: 'Vessel F', vesselType: 'Type 1', surveyType: 'Survey Z', CreatedOn: '2024-03-10', CreatedBy: 'Admin F' },
    { id: 7, name: 'Michael Wilson', vessel: 'Vessel G', vesselType: 'Type 3', surveyType: 'Survey X', CreatedOn: '2024-01-25', CreatedBy: 'Admin G' },
    { id: 8, name: 'Sarah Taylor', vessel: 'Vessel H', vesselType: 'Type 2', surveyType: 'Survey Y', CreatedOn: '2024-02-18', CreatedBy: 'Admin H' },
    { id: 9, name: 'Daniel Anderson', vessel: 'Vessel I', vesselType: 'Type 3', surveyType: 'Survey Z', CreatedOn: '2024-03-12', CreatedBy: 'Admin I' },
    { id: 10, name: 'Laura Martinez', vessel: 'Vessel J', vesselType: 'Type 1', surveyType: 'Survey X', CreatedOn: '2024-01-28', CreatedBy: 'Admin J' },
];


const RequestList = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const filteredData = dummyData.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.vessel.toLowerCase().includes(search.toLowerCase()) || item.vesselType.toLowerCase().includes(search.toLowerCase()) ||
        item.surveyType.toLowerCase().includes(search.toLowerCase()) ||
        item.CreatedBy.toLowerCase().includes(search.toLowerCase())
    );

    const handleCreateNewRequest = () => {
        navigate('/addrequest');
    };

    const handleViewRequest = (id) => {
        navigate('/viewrequest');
    };

    const handleEditDepartment = (id) => {
        const request = dummyData.find(item => item.id === id);
        navigate('/addrequest', { state: { flow: 'edit', request } });
    };
    

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list">
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div className="card-listing-section">
                                    <div className="right-wrapper" >
                                        <div>
                                            <h1 className="titlereg" style={{ marginLeft: '-21px' }}>Request Lists</h1>
                                            <Paper>
                                                <div className="search-bar-container" style={{ marginTop: '-23px' }}>
                                                    <TextField
                                                        className="search-bar"
                                                        label="Search"
                                                        variant="outlined"
                                                        value={search}
                                                        onChange={handleSearchChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <Search />
                                                            ),
                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                marginTop: '-11px',
                                                                fontFamily: 'Segoe UI Historic',
                                                            },
                                                            '& .MuiInputLabel-shrink': {
                                                                marginTop: '0px',
                                                            },
                                                            '& .MuiOutlinedInput-input': {
                                                                height: "25px",
                                                                margin: '4px',
                                                                fontFamily: 'Segoe UI Historic',
                                                            }
                                                        }}
                                                    />
                                                    <AddToPhotosIcon sx={{ fontSize: "2rem", color: "green", cursor: "pointer" }} onClick={handleCreateNewRequest} />
                                                </div>
                                                <TableContainer className="table-container">
                                                    <Table>
                                                        <TableHead className="table-header">
                                                            <TableRow>
                                                                <TableCell className='table-head-new' style={{ width: "5%" }}>ID</TableCell>
                                                                <TableCell className='table-head-new'>Name</TableCell>
                                                                
                                                                <TableCell className='table-head-new'>Vessel</TableCell>
                                                                <TableCell className='table-head-new'>Vessel Type</TableCell>
                                                                <TableCell className='table-head-new'>Survey Type</TableCell>
                                                                <TableCell className='table-head-new'>Created On</TableCell>
                                                                <TableCell className='table-head-new'>Created By</TableCell>
                                                                <TableCell className='table-head-new'  style={{ width: "15%" }}>Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {filteredData.map((row) => (
                                                                <TableRow key={row.id}>
                                                                    <TableCell className='table-cell-survey'>{row.id}</TableCell>
                                                                    <TableCell className='table-cell-survey'>{row.name}</TableCell>
                                                                    
                                                                    <TableCell className='table-cell-survey'>{row.vessel}</TableCell>
                                                                    <TableCell className='table-cell-survey'>{row.vesselType}</TableCell>
                                                                    <TableCell className='table-cell-survey'>{row.surveyType}</TableCell>
                                                                    <TableCell className='table-cell-survey'>{row.CreatedOn}</TableCell>
                                                                    <TableCell className='table-cell-survey'>{row.CreatedBy}</TableCell>
                                                                    <TableCell className='table-cell-survey'>
                                                                        <div className="action-buttons">
                                                                            <IconButton sx={{padding:'2px'}} className="action-button" onClick={() => handleViewRequest(row.id)}>
                                                                                <Visibility />
                                                                            </IconButton>
                                                                            <IconButton sx={{ padding: '2px' }} className="action-button" onClick={() => handleEditDepartment(row.id)}>
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
};

export default RequestList;
