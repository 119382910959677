import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import env from "../../../config";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LoaderDashboard from "../../LoaderDashboard";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, MenuItem, Select, FormControl, InputLabel, Checkbox, ListItemText,
    OutlinedInput, TextField, Tooltip, IconButton,
    Box, Grid
} from '@mui/material';
import { Search, Visibility } from '@mui/icons-material';
import './ViewSurveys.css';
import Header from "../../../Header/Header";
import LeftNav from "../../LeftNav/LeftNav";

const ViewSurveys = () => {
    // Initialize state for surveys, loading status, selected tab, and filtered surveys
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();
    const flow = location.state?.flow || "";

    useEffect(() => {
        fetchSurveys();
    }, []);

    // Fetch surveys data from the backend API
    const fetchSurveys = async () => {
        try {
            // const response = await axios.get(env.backend + "/api/survey");
            // setSurveys(Object.values(response.data));
            setSurveys(location.state?.surveys);

            setLoading(false);
        } catch (error) {
            // Handle errors during the fetch request
            console.error("Error fetching surveys:", error);
            setLoading(true);
            // alert("Error fetching surveys");
            localStorage.clear()
            window.location.replace('/')
        }
    };

    // Map to group surveys by companyName
    const surveyMap = new Map();
    surveys.forEach((survey) => {
        const { companyName, ...rest } = survey;
        if (surveyMap.has(companyName)) {
            surveyMap.get(companyName).push({ companyName, ...rest });
        } else {
            surveyMap.set(companyName, [{ companyName, ...rest }]);
        }
    });

    // Initialize state for surveys, loading status, selected tab, and filtered surveys
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState(flow === "Active" ? ["New", "In-Progress"] : flow === "Rejected" ? ["In-Progress"] : [flow]);
    const [filteredSurveys, setFilteredSurveys] = useState([]);
    const [search, setSearch] = useState('');
    const companyNames = Array.from(surveyMap.keys());

    useEffect(() => {
        filterSurveys();
    }, [selectedCompanies, selectedStatuses, surveys, search]);

    // Handle company selection changes
    const handleCompanyChange = (event) => {
        setSelectedCompanies(event.target.value);
    };

    // Filter surveys based on selected companies, statuses, and search input
    const filterSurveys = () => {
        let surveys = [];

        // Include surveys from selected companies or all if no company is selected
        if (selectedCompanies.length > 0) {
            selectedCompanies.forEach(company => {
                if (surveyMap.has(company)) {
                    surveys.push(...surveyMap.get(company));
                }
            });
        } else {
            surveyMap.forEach((value) => {
                surveys.push(...value);
            });
        }


        let transformedSurveys = [];


        surveys.forEach(survey => {
            const statusMap = {};

            // Group certificates by status
            survey.surveyCertificateList.forEach(cert => {
                const status = cert.surveyCertificateStatus;
                if (!statusMap[status]) {
                    statusMap[status] = [];
                }
                statusMap[status].push(cert);
            });

            // Filter and transform surveys based on status and flow conditions
            Object.keys(statusMap).forEach(status => {
                if ((status === "In-Progress" || status === "New") && selectedStatuses.includes("Active")) {
                    transformedSurveys.push({
                        ...survey,
                        surveyCertificateList: statusMap[status],
                        originalStatus: status
                    });
                }
                else if ((status === "In-Progress" && flow === "Rejected")) {
                    const hasComment = statusMap[status].some(cert => cert.comment);
                    if (hasComment) {
                        transformedSurveys.push({
                            ...survey,
                            surveyCertificateList: statusMap[status],
                            originalStatus: status
                        });
                    }
                }
                else if (selectedStatuses.length === 0 || selectedStatuses.includes(status)) {
                    transformedSurveys.push({
                        ...survey,
                        surveyCertificateList: statusMap[status],
                        originalStatus: status
                    });
                }
            });
        });

        // Additional filter for surveys with approved certificates
        if (selectedStatuses.length === 0) {
            surveys.forEach(survey => {
                const filteredCertificateList = survey.surveyCertificateList.filter(cert => cert.surveyCertificateStatus !== "Approved");
                if (filteredCertificateList.length > 0) {
                    transformedSurveys.push({
                        ...survey,
                        surveyCertificateList: filteredCertificateList,
                        originalStatus: filteredCertificateList[0]?.surveyCertificateStatus
                    });
                }
            });
        }

        // Filter surveys based on search input
        const filteredData = transformedSurveys.filter((item) =>
            (item?.companyName?.toLowerCase().includes(search?.toLowerCase()) || '') ||
            (item?.surveyName?.toLowerCase().includes(search?.toLowerCase()) || '') ||
            (item?.vesselName?.toLowerCase().includes(search?.toLowerCase()) || '')
        );
        const sorted=filteredData.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
        setFilteredSurveys(sorted); // Update filtered surveys state
    };

    // Filter certificates based on selected statuses
    const getFilteredCertificates = (certificates) => {
        if (selectedStatuses.length === 0) {
            return certificates;
        }
        return certificates.filter(cert => selectedStatuses.includes(cert.surveyCertificateStatus));
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    // Handle navigation to view more details of a survey
    const handleViewRequest = (survey) => {
        navigate('/viewmoresurvey', {
            state: {
                survey: survey,
                flow: flow,
                surveys:filteredSurveys,
            }
        });
    };

    // Handle tab change and update selected statuses accordingly
    const handleTabChange = (index) => {
        setSelectedTab(index);
        if (index === 1) {
            setSelectedStatuses(["Active"]);
        } else {
            setSelectedStatuses([flow]);
        }
    };

    const statusOptions = ["New", "In-Progress"];
    // Format date from string to DD/MM/YYYY format
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return '';
        }
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }} selectedIndex={selectedTab} onSelect={handleTabChange}>
                    <TabList className="hidden-tab-list">
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div className="card-listing-section">
                                    <div className="right-wrapper">
                                        <div>
                                            <h1><div className="heading-survey">{flow} Surveys</div></h1>

                                        </div>
                                        {
                                            loading ? (<LoaderDashboard />) : (
                                                <Paper>
                                                    <div className="search-bar-container" style={{ marginTop: '-23px' }}>
                                                        <Grid container spacing={2} style={{ display: 'contents' }}>

                                                            <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel sx={{
                                                                        "&.MuiInputLabel-root": {
                                                                            marginTop: '-11px',
                                                                            fontFamily: 'Segoe UI Historic'
                                                                        },
                                                                        "&.MuiInputLabel-shrink": {
                                                                            marginTop: '0px'
                                                                        }
                                                                    }}>Companies</InputLabel>
                                                                    <Select
                                                                        sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' } }}
                                                                        multiple
                                                                        value={selectedCompanies}
                                                                        onChange={handleCompanyChange}
                                                                        input={<OutlinedInput label="Companies" />}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                    >
                                                                        {companyNames.map((name) => (
                                                                            <MenuItem key={name} value={name} sx={{
                                                                                paddingTop: '0px',
                                                                                paddingBottom: '0px',
                                                                                paddingLeft: '0px',
                                                                                paddingRight: '2px',
                                                                                fontFamily: 'Segoe UI Historic'
                                                                            }}>
                                                                                <Checkbox checked={selectedCompanies.indexOf(name) > -1} />
                                                                                <ListItemText primary={name} sx={{
                                                                                    '& .MuiListItemText-primary': {
                                                                                        fontSize: '14px',
                                                                                        fontFamily: 'Segoe UI Historic'
                                                                                    }
                                                                                }} />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            {flow === "Active" && (

                                                                <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                                                    <FormControl fullWidth>
                                                                        <InputLabel sx={{
                                                                            "&.MuiInputLabel-root": {
                                                                                marginTop: '-11px',
                                                                                fontFamily: 'Segoe UI Historic'
                                                                            },
                                                                            "&.MuiInputLabel-shrink": {
                                                                                marginTop: '0px'
                                                                            }
                                                                        }} >Status</InputLabel>
                                                                        <Select  
                                                                            sx={{ "&.MuiInputBase-input, .MuiOutlinedInput-input": { padding: "5px", fontFamily: 'Segoe UI Historic' }}}                                                                                                                                                       
                                                                            multiple
                                                                            value={selectedStatuses}
                                                                            onChange={(event) => setSelectedStatuses(event.target.value)}
                                                                            input={<OutlinedInput label="Status" />}
                                                                            renderValue={(selected) => selected.join(', ')}
                                                                        >
                                                                            {statusOptions.map((status) => (
                                                                                <MenuItem key={status} value={status} sx={{
                                                                                    paddingTop: '0px',
                                                                                    paddingBottom: '0px',
                                                                                    paddingLeft: '0px',
                                                                                    paddingRight: '2px',
                                                                                    fontFamily: 'Segoe UI Historic'
                                                                                }}>
                                                                                    <Checkbox checked={selectedStatuses.indexOf(status) > -1} />
                                                                                    <ListItemText primary={status} sx={{
                                                                                        '& .MuiListItemText-primary': {
                                                                                            fontSize: '14px',
                                                                                            fontFamily: 'Segoe UI Historic'
                                                                                        }
                                                                                    }} />
                                                                                </MenuItem>
                                                                            ))}
                                                                            
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12} sm={6} sx={{ '&.MuiGrid-item': { paddingLeft: '0px', paddingTop: '0px' } }}>
                                                                <FormControl fullWidth>



                                                                    <TextField
                                                                        className="search-bar"
                                                                        label="Search"
                                                                        variant="outlined"
                                                                        value={search}
                                                                        onChange={handleSearchChange}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <Search />
                                                                            ),
                                                                        }}
                                                                      
                                                                    
                                                                    sx={{
                                                                        '& .MuiInputLabel-root': {
                                                                            marginTop: '-11px', 
                                                                            fontFamily: 'Segoe UI Historic',
                                                                        },
                                                                        '& .MuiInputLabel-shrink': {
                                                                            marginTop: '0px', 
                                                                        },
                                                                        '& .MuiOutlinedInput-input': {
                                                                            height: "25px",
                                                                            margin: '4px',
                                                                            fontFamily: 'Segoe UI Historic',
                                                                        }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </div>

                                                    <TableContainer component={Paper} sx={{ maxHeight: '77vh'}} className="table-container">
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell className='table-head-new'>Company</TableCell>
                                                                    <TableCell className='table-head-new'>Vessel Name</TableCell>
                                                                    <TableCell className='table-head-new'>Survey Id</TableCell>
                                                                    <TableCell className='table-head-new'>Survey Name</TableCell>
                                                                    <TableCell className='table-head-new'>Status</TableCell>
                                                                    <TableCell className='table-head-new'>{(flow === "New") ? "Created Date" : (flow === "Approved") ? "Due Date" : "Updated Date"}</TableCell>
                                                                    <TableCell className='table-head-new'>Actions</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {filteredSurveys.map((survey, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell className='table-cell-new' title={survey.companyName.length > 15 ? survey.companyName : ''}>
                                                                            {survey.companyName.length > 15 ? `${survey.companyName.slice(0, 15)}...` : survey.companyName}
                                                                        </TableCell>
                                                                        <TableCell className='table-cell-new'>{survey.vesselName}</TableCell>
                                                                        <TableCell className='table-cell-new' title={survey.surveyId.length > 10 ? survey.surveyId : ''}>
                                                                            {survey.surveyId.length > 10 ? `${survey.surveyId.slice(0, 10)}...` : survey.surveyId}
                                                                        </TableCell>
                                                                        <TableCell className='table-cell-new' title={survey.surveyName.length > 15 ? survey.surveyName : ''}>
                                                                            {survey.surveyName.length > 15 ? `${survey.surveyName.slice(0, 15)}...` : survey.surveyName}
                                                                        </TableCell>
                                                                        <TableCell className='table-cell-new'>{survey.originalStatus}</TableCell>
                                                                        <TableCell className='table-cell-new'>{survey.createdOn ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(survey.createdOn)) : ''}</TableCell>
                                                                        <TableCell className='table-cell-new'>
                                                                            <div className="action-buttons" style={{ width: '40%'}}>
                                                                                <Tooltip title={"View Survey Details"}>
                                                                                    <IconButton onClick={() => handleViewRequest(survey)} sx={{ padding: '2px' }}>
                                                                                        <Visibility />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
}

export default ViewSurveys;
