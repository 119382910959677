import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../../Header/Header";
import LeftNav from "../../Components/LeftNav/LeftNav";
import { FormControl, FormControlLabel, RadioGroup, Radio, Button, Box } from '@mui/material';
import './SurveyChecklist.css'


function SurveyChecklist() {
    const navigate = useNavigate();
    const location = useLocation();
    const checklistItems = [
        { id: '1', label: 'Has the validity of all class and statutory certificates been checked? - This includes other SOLAS (SE; SR; SC; etc.); Load Line; SMC; ISSC; and MARPOL Certificates; Safe Manning Document; AFS Certificate; and Officers and Crew certificates as required by the STCW Convention.' },
        { id: '2', label: 'Have any changes been made or any new equipment installed which would affect thevalidity of the Cargo Ship safety equipment Certificate?(If YES, provide details under REMARKS, Section 8)' },
        { id: '3', label: 'Have the arrangements provided on board been compared to those listed in the ships CRS Record of Approved Cargo Ship Safety Equipment as well as the Record ofEquipment for the Cargo Ship Safety Equipment Certificate (FORM E) and no changes noted? (If NO, provide details under Section A)' },
        { id: '4.0', label: 'Have Log-Book entries made for the following:' },
        { id: '4.a', label: 'Last full muster of the crew for Abandon ship drill and Fire drills (to be done monthly or at 25% crew change)?' },
        { id: '4.b', label: 'On board training and instructions? (to be given not later than 2 weeks after new crew joining)' },
        { id: '4.c', label: 'All Boats were turned out? (to be done at least once monthly) ' },
        { id: '4.d', label: 'All Boats were lowered and maneuvered? (to be done at least once every three months).' },
        { id: '4.e', label: 'Weekly inspections of survival craft, rescue boats, their engines and their launching appliances and monthly inspections of life-saving appliances, including lifeboat equipment?' },
        { id: '4.f', label: 'Crew members with enclosed-space entry or rescue responsibilities to participate in an Enclosed-Space Entry and Rescue Drill? (to be held once every two months).' },
        { id: '4.g', label: 'Rotational deployment of Marine Evacuation Systems (MES).' },
        { id: '4.h', label: 'Records of Navigational activities and incidents; and daily reporting have been maintained?' },
        { id: '5', label: 'Have the Checklists and Instructions for On board Maintenance of the Life-Saving Aooliances are on board, been confirmed?' },
        { id: '6', label: 'Have the Training Manuals and Training Aids for the Life-Saving Appliances are available on board in the workinQ lanQuaQe of the ship, been confirmed?' },
        { id: '7', label: 'Has a maintenance plan for fire protection systems and firefighting systems and aooliances been sighted on board the ship?' },
        { id: '8', label: 'Has a table or curve of residual deviations for the Magnetic Compass has been provided on board, been confirmed?' },
        { id: '9', label: 'Has the Magnetic Compass Deviation Book available on board, has been properly maintained, been confirmed?' },
        { id: '10', label: 'Has a diagram of the radar installations shadow sectors is displayed, been confirmed?' },
        { id: '11', label: 'Confirming that Nautical Charts (and/or an electronic chart display and information system (ECDIS)) and Nautical Publications (Such as Lists of Lights, Notice to Mariners, Tide Tables, Sailing Directions, Nautical Almanac, etc.) necessary for the intended voyages are available on board and have been updated.' },
        { id: '12', label: 'Has a copy of the updated INTERNATIONAL CODE OF SIGNALS is on board, been confirmed?' },
        { id: '13', label: 'Has a copy of Volume Ill of the International Aeronautical and Maritime Search and Rescue (IAMSAR) Manual is on board, been confirmed?' },
        { id: '14', label: 'Have the publications of SOLAS convention, MARPOL convention, COLREGS, PSC Procedures, LSA code, ITC69, lntI Load Line convention, FSS code, STCW convention, BCH/ IBC Code, IMDG code, IMSBC code, Timber code, ISM code, ISPS code, Medical publications, etc...are on board, been confirmed ? ' },
        { id: '15', label: 'Confirming that the Life-Saving Signals to be used by ships, aircraft or persons in distress are available on board.' },
        { id: '16', label: 'Have Operational Manuals and, where appropriate, Maintenance Manuals for all Navigational Equipment are provided on board, been confirmed?' },
        { id: '17', label: 'Confirming, when appropriate, that there is a Special List, Manifest or Stowage Plan for the Carriage of Dangerous Goods' },
        { id: '18.0', label: ' Was the following Communications Equipment verified on board and in satisfactory condition:'},
        { id: '18.a', label: 'Two-way VHF radiotelephone apparatus. (Complying with Resolution A.762(18))' },
        { id: '18.b', label: 'Search and Rescue Locating Device(s) (SART) -Before called Radar Transponders.' },
        { id: '18.c', label: 'Emergency Position Indicating Radio Beacon (EPIRB): ' },
        { id: '18.d', label: '12 Red parachute Flares stowed on/near Navigation Bridge, along with instructions for use?' },
        { id: '18.e', label: 'On board emergency two-way communications system between emergency control stations, including marine evacuation systems (if applicable), muster and embarkation stations and strategic positions on board?' },
        { id: '18.f', label: 'General Emergency Alarm and public address system, including tests of the system?' },
        { id: '18.g', label: 'Line throwing appliances and lines (230m) along with instructions for use? (At least 4 rockets and lines are to be provided).' },
        { id: '18.h', label: 'Daylight signaling lamp, including test using batteries/main s.ource of electrical power?' },
        { id: '18.i', label: 'Coordinated Universal Time (UTC) is used when releasing danger messages?' },
        { id: '18.j', label: 'Telephone or other means to communicate heading information to the emergency steering position, if provided? For ships pf 500 GRT and upwards,constructed on or after 1 February 1992 is there a means of providing heading information visually to the emeraency steerina position, if provided?' },
        { id: '19.0', label: 'For Ships Constructed before 01 July 2002: Have the following Navigation Equipment been examined and tested as necessary and found in satisfactorycondition:'},
        { id: '19.a', label: 'Standard Magnetic Compass? Steering Compass unless heading information is provided by the Standard Magnetic Compass to the main steering position? Means for taking bearing over a 360deg arc, as far as practicable? Compassbowl filled with liauid free of bubbles?' },
        { id: '19.b', label: 'Spare magnetic compass, interchangeable with the standard magnetic compass? (If the ship is not fitted with Gyro-Compass).' },
        { id: '19.c', label: 'Gyro-Compass and any Gyro-repeaters, including examining alignment of the master and all reoeaters?' },
        { id: '19.d', label: 'RADAR(s) - 9GHz / 3 GHz frequency band - including facilities for plotting and ARPA?' },
        { id: '19.e', label: 'Echo-Sounding Device, including additional supplies (e.g. paper, and ink)?' },
        { id: '19.f', label: 'Speed and distance indicator? And Speed and distance indicator through the water if ARPA is fitted?' },
        { id: '19.g', label: 'The indicators showing Rudder Angle, Propeller Revolutions, and if fitted with variable pitch or lateral thrust propellers, pitch and operational mode, readable from the conning position?' },
        { id: '19.h', label: 'Rate of turn indicator?' },
        { id: '19.i', label: 'Global Navigation Satellite System or Terrestrial Radio Navigation System (GPS)?' },
        { id: '19.j', label: 'Automatic Identification System (AIS)?' },
        { id: '20.0', label: 'For Ships Constructed on or after 01 July 2002: Have the following Navigation Equipment been examined and tested as necessary and found in satisfactorycondition'},
        { id: '20.a', label: 'Standard Magnetic Compass, or other means, independent of any power supply,to determine the ships heading and display the reading at the main steering position.' },
        { id: '20.b', label: 'Spare magnetic compass, interchangeable with the magnetic compass? (If the ship is not fitted with Gyro-Compass).' },
        { id: '20.c', label: 'Radar reflector, or other means, to enable detection by ships navigating by radar at both 9 and 3 GHz.' },
        { id: '20.d', label: 'Pelorus or compass bearing device, or other means, independent of any power suoolv, to take bearinos over an arc of the horizon 360dearee' },
        { id: '20.e', label: 'Back-up arrangements to meet the functional requirements of the nautical charts, if this function is partly or fully fulfilled by electronic means - Anappropriate folio of paper nautical charts may be used as a back-up arrangements of ECDIS' },
        { id: '20.f', label: 'Receiver for a global navigation satellite system or a terrestrial radio navigation system (GPS), or other means, suitable for use at all times throughout the intended vovaoe to establish and update the ships position bv automatic means.' },
        { id: '20.g', label: 'When the ships bridge is totally enclosed and unless the Administration determines otherwise, a Sound Reception System, or other means, to enable the officer in charge of the navigational watch to hear sound signals and determine their direction.' },
        { id: '20.h', label: 'Echo-Sounding device, or other electronic means, to measure and display the available depth of water.' },
        { id: '20.i', label: '9 GHz radar, or other means, to determine and display the range and bearing of radar transponders and of other surface objects to assist in navigation and in collision avoidance.' },
        { id: '20.j', label: 'Electronic plotting aid, or other means, to plot electronically the range and bearing of targets to determine collision risk' },
        { id: '20.k', label: 'Speed and distance measuring device, or other means, to indicate speed and distance through the water.' },
        { id: '20.l', label: 'Transmitting heading device, or other means, to transmit heading information to other eauipment (radar), plotting aid and AIS.' },
        { id: '20.m', label: 'An Automatic Identification System (AIS) that automatically provides navigational information to appropriately equipped shore stations, other ships and aircraft.' },
        { id: '20.n', label: 'A Gyro-Compass, or other means, to determine and display their heading by shipborne non-magnetic means, being clearly readable by the helmsman at the main steering position. These means shall also transmit heading information forinput to other eauipment (radar, AIS and tracking aid).' },
        { id: '20.o', label: 'A Gyro-Compass heading repeater, or other means, to supply heading information visually at the emergency steering position if provided.' },
        { id: '20.p', label: 'A Gyro-Compass bearing repeater, or other means, to take bearings, over an arc of the horizon of 360 , using the gyro-compass or other means.' },
        { id: '20.q', label: 'An Automatic Tracking Aid, or other means, to plot automatically the range and bearing of other targets to determine collision risk.' },
        { id: '20.r', label: 'Rudder, propeller, thrust, pitch and operational mode indicators, or other means, to determine and display rudder angle, propeller revolutions, the force and direction of thrust and, if applicable, the force and direction of lateral thrust and the pitch and operational mode, all to be readable from the conning position.' },
        { id: '20.s', label: 'A 3 GHz radar or, a second 9 GHz radar, or other means, to determine and display the range and bearing of other surface objects to assist in navigation andin collision avoidance, which are functionally independent.' },
        { id: '20.t', label: 'A second automatic tracking aid, or other means, to plot automatically the range and bearing of other targets to determine collision risk.' },
        { id: '20.u', label: 'An automatic radar plotting aid (ARPA), or other means, to plot automatically the range and bearing of at least 20 other targets, connected to a device to indicate speed and distance through the water, to determine collision risks and simulate a trial maneuver' },
        { id: '20.v', label: 'A heading or track control system, or other means, to automatically control and keep to a heading and/or straight track.' },
        { id: '20.w', label: 'A rate-of-turn indicator, or other means, to determine and display the rate of turn.' },
        { id: '20.x', label: 'A speed and distance measuring device, or other means, to indicate speed and distance over the ground in the forward and athwart ships direction.' },
        { id: '21', label: 'Has the Bridge Navigational Watch Alarm System (BNWAS) been examined and tested as necessary and found in satisfactory condition?' },
        { id: '22', label: 'Has the Long-Range Identification and Tracking (LRIT) System Conformance test report by an approved Application Service Provider (ASP) is on board, been checked? - Is not required for ships certified for Sea Area A1 and fitted with an automatic identification system (AIS).' },
        { id: '23', label: 'Has the Electronic Chart Display and Information System (ECDIS) been examined and tested as necessary and found in satisfactory condition?' },
        { id: '24', label: 'Have the provisions and specifications of the pilot ladders and mechanical hoists/pilot transfer arrangements, been checked?' },
        { id: '25', label: 'Have the means of embarkation and disembarkation from ships for use in port and in port- related operations, such as gangways and accommodation ladders, are in satisfactory condition, as aoolicable, been checked?' },
        { id: '26', label: 'Has the provision of an appropriate instrument for measuring the concentration of gas or oxygen in the air together with detailed instructions for its use, been checked? When transporting a Solid Bulk Cargo which is liable to emit a toxic or flammable gas, or cause oxygen depletion in the cargo space.' },
        { id: '27', label: 'Have the required the navigation lights, shapes and sound signaling equipments are in order ((COLREG) in force, rules 20 to 24, 27 to 30 and 33); been checked? - Shapes generally include diamonds, balls, and cvlinders or combination thereof.' },
        { id: '28', label: 'Has the vessel been fitted with a Voyage Data Recorder (VDR)?' },
        { id: '29', label: 'Was the Voyage Data Recorder (VDR) examined and tested by an approved testing or servicing facility, and was a copy of the approval certificate for the facility on board, along with the annual performance report?' },
        { id: '30', label: 'Has the vessel been issued an exemption certificate from the requiren:ients of a VDR?' },
        { id: '31', label: 'Checking that Emergency Instructions are available for each person on board.' },
        { id: '32', label: 'Have the copies of the suitably updated MUSTER LIST are posted in conspicuous places(such as navigation bridge, engine room, and accommodation spaces) and in a language understood bv all persons on board, been confirmed?' },
        { id: '33', label: 'Confirming that there are posters (including survival craft operating/launching instructions) or signs, easily seen under emergency lighting conditions, in the vicinity of survival craft and their launching stations and containers, brackets, racks, and other similar stowage locations for Life-Savino Aooliances.' },
        { id: '34', label: 'It was verified that the person-in-charge if a survival craft has a list of the survival craft crew. Additionally, for lifeboats the second-in-command has a list of the lifeboat crew?' },
        { id: '35', label: 'The lighting of the muster and embarkation stations and the alleyways, stairways and exits giving access to the muster station, including emergency lighting and embarkation ladder were examined and tested and found in satisfactorv condition.' },
        { id: '36.0', label: 'LIFEBOATS' },
        { id: '36.a', label: 'Were all lifeboats examined, including an annual thorough examination, a dynamic testing of the winch brake, and testing of on-load release gear for the lifeboat launching appliances, as far as practicable and all found satisfactory?' },
        { id: '36.b', label: '. Has each lifeboats launching appliance and on-load release gear been thoroughly examined, repaired and/or replaced and tested under the load specified at the below item?' },
        { id: '36.c', label: 'Has each lifeboat winch brake been dynamically tested at 1.1 times the total weight of the fully loaded lifeboat including provisions and persons at an interval not exceeding five (5) years?' },
        { id: '36.d', label: 'Examination of the embarkation arrangements and launching appliances for each lifeboat, and for each life rafts (if launchino aooliances are provided).' },
        { id: '36.e', label: 'Each Lifeboat to be lowered to the embarkation position or, if the stowage position is the embarkation position, lowered a short distance.' },
        { id: '36.f', label: 'If practicable, one of the lifeboats to be lowered to the water.If for any reason, it was not possible to lower the lifeboats, the Surveyor shall state the reason the test could not be carried out' },
        { id: '36.g', label: 'Were all lifeboats lowered and where practicable launched at water and all found satisfactory?' },
        { id: '36.h', label: 'Were motor lifeboat engines and propulsion tested both in ahead and astern modes and found satisfactory?' },
        { id: '36.i', label: 'Have all lifeboats been examined and engines and propeller gear tested in ahead and astern modes and found satisfactory?' },
        { id: '36.j', label: 'Have all equipments of lifeboat been examined and found in satisfactory condition?' },
        { id: '36.k', label: 'Is retro-reflective material on lifeboats in satisfactory condition?. (If missing, retro-reflective material to be fitted on the proper locations as per IMO resolution A.658 (16)).' },
        { id: '36.l', label: 'Checking condition and operation of water spray and air supply systems that are in Partially/Totally Enclosed Lifeboats and have self-contained air support svstems.' },
        { id: '36.m', label: '' },
        { id: '37.0', label: 'RESCUE BOAT'},
        { id: '37.a', label: 'Examination of each rescue boat, including its equipment. For inflatable rescue boats, confirming that they are stowed in a fully inflated condition.' },
        { id: '37.b', label: 'Examination of the embarkation and recovery arrangements for each rescue boat, and if practicable, the rescue boat(s) to be lowered to the water and itsrecoverv demonstrated.' },
        { id: '37.c', label: 'Testing of the engine of the rescue boat(s), when so fitted, start satisfactorily and operate both ahead and astern.' },
        { id: '37.d', label: 'Testing of on-load release gear for the rescue boat launching appliances, as far as practicable and all found satisfactory.' },
        { id: '37.e', label: 'Has the rescue boat been examined with all required fittings, launching, and recovery arrangements and engine tested to operate both ahead and astern and been found or placed in satisfactory condition?' },
        { id: '37.f', label: 'Has the rescue boats launching appliance been thoroughly examined and the winch brake dynamically tested at 1.1 times the total weight of the fully loaded rescue boat including provisions and persons at intervals not exceeding 5 years ? ' },
        { id: '37.g', label: 'Have all equipments of rescue boat been examined and found in satisfactory condition?' },
        { id: '37.h', label: 'Is retro-reflective material on rescue boat in satisfactory condition? (If missing,retro-reflective material to be fitted on the proper locations as per IMO resolution A.658(16))' },
        { id: '37.i', label: '' },
        { id: '38.0', label: 'LIFE RAFTS' },
        { id: '38.a', label: 'Have inflatable life rafts been serviced in the past 12 months?' },
        { id: '38.b', label: 'Have hydrostatic release units been serviced in the past 12 months?' },
        { id: '38.c', label: 'Life raft float free arrangements, including weak link attached to sea painter, were examined and found satisfactorv.' },
        { id: '38.d', label: 'Provision for marking each life raft with the name and port of registry of the ship to which it is to be fitted, in such a form that the ship identification can be changed at any time without opening the container. (Example - Watertight tube on lanvard attached to the raft).' },
        { id: '38.e', label: 'For life rafts provided for easy side to side transfer, Verification that they are less than 185 KQ is to be confirmed.' },
        { id: '38.f', label: 'Operation of the launching appliances for davit-launched life rafts to be demonstrated and automatic release hooks of davit-launched life rafts to be tested.' },
        { id: '38.g', label: 'If the life raft is serviced by a launching appliance, has the launching appliance been thoroughly examined and the winch brake dynamically tested at 1.1 times the total weight of the fully loaded lifeboat including provisions and persons at interval not exceeding 5 years?' },
        { id: '38.h', label: 'Is retro-reflective material on life rafts in satisfactory condition? (If missing, retro-reflective material to be fitted on the proper locations as per IMO resolution A.658 (16)).' },
        { id: '39', label: 'FALLS OF LAUNCHING APPLIANCES: Have the Falls used in launching appliances been periodically inspected, and been renewed when necessary due to deterioration of the falls OR at intervals of not more than 5 years, whichever is the earlier?' },
        { id: '40.0', label: ' Visual Signals(Pyrotechnics for lifeboats / rigid life rafts):'},
        { id: '40.a', label: 'Four (4) red parachute flares stowed in lifeboat I rigid life raft' },
        { id: '40.b', label: 'Six (6) red hand flares stowed in lifeboat / rigid life raft.' },
        { id: '40.c', label: 'Two (2) buoyant smoke signals stowed in lifeboat/ rigid life raft.' },
        { id: '40.d', label: 'Indicate the type and date of any pyrotechnic signals provided on board in addition to those required: NIL' },
        { id: '41.0', label: ' The following Personal Life Saving Appliances were examined and found or placed in satisfactory condition and properly stowed:'},
        { id: '41.a', label: 'Life buoys including all required attachments - such as self-igniting lights, self- activating smoke signals and buoyant line -, retro-reflective material, and marking.' },
        { id: '41.b', label: 'Life jackets with whistles, retro-reflective material, lights; and marking' },
        { id: '41.c', label: 'Immersion suits' },
        { id: '41.d', label: 'Have immersion suits been tested according to manufacturers specifications?' },
        { id: '42', label: 'Checking whether any fire on board necessitating the operation of the fixed fire- extinguishing systems or the portable fire extinguishers, has occurred since the last survey and the entries into the ships log book.' },
        { id: '43', label: 'Has a Fire Safety Operational Booklet and Fire Safety Training Manual been provided in each crew mess and recreational room in or in each cabin, and is it written in the workinglanguage of the ship, been confirmed?' },
        { id: '44', label: 'Has a maintenance plan for fire protection systems and fire-fighting systems and annliances been siahted on board the shio?' },
        { id: '45', label: 'Has the operational readiness and maintenance of fire-fighting systems, been checked?' },
        { id: '46', label: 'Have all items of fire-fighting, fire detection, and safety equipment (including EEBDs) been verified against the ships FIRE CONTROL PLAN and the CRS Record of Approved Cargo Ship Safety Equipment?' },
        { id: '47', label: 'Have the updated FIRE CONTROL PLANS are permanently exhibited or, alternatively, that Emergency Booklets for Fire-Ficihtinci have been provided on board, been confirmed?' },
        { id: '48', label: 'Has a duplicate copy of the updated FIRE CONTROL PLAN or the Emergency Booklet is available in a prominently marked enclosure external to the ships deckhouse, been confirmed?' },
        { id: '49.0', label: 'Were the following items and systems (as fitted) examined and/or tested and found or placed in satisfactory condition:' },
        { id: '49.a', label: 'Fire Pumps including the Emergency Fire Pump demonstrated to deliver two jets of water are produced simultaneously from different hydrants at any part of the ship whilst the required pressure is maintained in the fire main.' },
        { id: '49.b', label: 'Fire Main including isolating valves, hydrants, fire hoses, nozzles, applicators and tools' },
        { id: '49.c', label: 'Fixed Gas Fire Extinguishing System (for cargo spaces and/or machinery spaces), including controls, storage bottles, piping, and nozzles and its means of operations is clearly marked' },
        { id: '49.d', label: 'Operating Instructions for the Fixed Gas Fire Extinguishing System are posted near the release and control station and in language(s) understood by the crew.' },
        { id: '49.e', label: 'Release Control Stations: For Fixed Carbon Dioxide (CO2) Fire-Extinguishing Systems for the protection of machinery spaces and cargo pump-rooms, where applicable, are provided with two separate controls, one for opening of the gas piping and one for discharging the gas from the storage container, each of them located in a release box clearly identified for the particular space.' },
        { id: '49.f', label: 'If a Fixed Gas Fire-Extinguishing System is not provided for c<;1rgo spaces, was an exemption certificate issued?' },
        { id: '49.g', label: 'Portable and Non-Portable Fire-Extinguishers.' },
        { id: '49.h', label: 'Fixed High Expansion Foam System including, foam generator, controls, and foam liquid, and its means of operations is clearly marked.' },
        { id: '49.i', label: 'Fixed Low Expansion Foam System including storage, piping, controls, and foam liquid, and its means of operations is clearly marked.' },
        { id: '49.j', label: 'Fixed Water-Based Systems: Fixed water spraying/sprinkling systems including piping, isolating valves, control stations, water suoolv and nozzles.' },
        { id: '49.k', label: 'Fixed Water-Based Systems: Fixed Local Application Fire Extinguishing Systems in category A machinery spaces.' },
        { id: '49.l', label: 'Firemans outfits, including self- contained breathing apparatus(SCBA), and spare cylinders.' },
        { id: '49.m', label: 'International Shore Connection.' },
        { id: '49.n', label: 'Are Paint Lockers and flammable liquid lockers, protected by an appropriate Fire-Extinauishina Arranaement aooroved by the Administration?' },
        { id: '49.o', label: 'Portable Combustible Gas Detectors for Closed Ro-Ro spaces.' },
        { id: '49.p', label: 'Ships carrying dangerous goods (additional personnel protection is required): Four (4) sets of full protective clothing ,Minimum two (2) self-contained breathina aooaratuses' },
        { id: '49.q', label: 'Fixed Fire Detection and Alarm Systems randomly tested.' },
        { id: '49.r', label: 'Sample extraction smoke detection system was tested.' },
        { id: '49.s', label: 'Remote controls for stopping fans and machinery, and shutting off fuel supplies(emergency shut off of fuel oil pumps and other pumps that discharge flammable liquids).' },
        { id: '49.t', label: 'The remote control means for closing the valves on the tanks that contain fuel oil, lubricatina oil and other flammable oils (auick closina valves).' },
        { id: '49.u', label: 'Remote Closing arrangements of ventilators annular spaces, skylights, doorways, funnel and tunnel as fitted. All Fire Dampers were proven to be structurally sound bv internal examination and free to operate' },
        { id: '49.v', label: 'Emergency Generator was tested. If not provided, proven battery system us functioning.' },
        { id: '50.0', label: 'Were the following items for the deep-fat cooking equipment examined and/or tested as required and found or placed in satisfactory condition:' },
        { id: '50.a', label: 'Was the equipment fitted with an automatic or manual fire extinguishing system?' },
        { id: '50.b', label: 'Was the malfunction/failure alarm for the primary and backup thermostat tested?' },
        { id: '50.c', label: 'Were the arrangements for automatically shutting off the electrical power upon activation of the fire-extinguishina svstem, tested?' },
        { id: '50.d', label: 'Was the local alarm for indicating operation of the fire-extinguishing system in the aallev tested and found audible under normal conditions?' },
        { id: '50.e', label: 'Were the controls for manual operation of the fire extinguishing system clearly labeled and posted for ready use by the crew?' },
        { id: '51.0', label: 'Emergency Escape Breathing Devices(EEBDs), the following items were examined and confirmed:' },
        { id: '51.a', label: 'The EEBD within the machinery spaces were available for ready use at easily visible places.' },
        { id: '51.b', label: 'At least two (2) EEBDs were available for ready use within the accommodation spaces.' },
        { id: '51.c', label: 'The cylinders for the EEBDs were in good condition and fully charged (including the spares).' },
        { id: '', label: 'ADDITIONAL REQUIREMENTS FOR TANKERS' },
        { id: '52', label: 'Was the Deck Foam System, including the supplies of foam concentrate examined and were the two (2) numbers of jets of water at the required pressure in the fire main is obtained when the system is in operation tested as required and found in satisfactory condition?' },
        { id: '53', label: 'Was the fixed fire-fighting and fire detection system for Cargo Pump Room examined and/or tested as reauired and found in satisfactory condition?' },
        { id: '54', label: 'Were the Portable oxygen/combustible gas detectors examined and/or tested as required and found in satisfactory condition?' },
        { id: '55', label: 'Was an external examination of piping and cutout valves of cargo tank and cargo pump room fixed fire-fiohtino system carried out as far as practicable and all found satisfactory?' },
        { id: '56.0', label: 'INERT GAS SYSTEM' },
        { id: '56.a', label: 'Have the instruction manuals for the inert gas system been provided; and has the correct operation of the inert gas system; been confirmed?' },
        { id: '56.b', label: 'Was the Inert Gas System (IGS) generally examined and found in satisfactory condition?' },
        { id: '56.c', label: 'External examination for any sign of gas or effluent leakage.' },
        { id: '56.d', label: 'Confirmation of the proper operation of both inert gas blowers.' },
        { id: '56.e', label: 'Observation of the operation of the scrubber-room ventilation system.' },
        { id: '56.f', label: 'Checking the deck water seal for automatic filling and draining.' },
        { id: '56.g', label: 'Examination of all remotely operated or automatically controlled valves and, in particular, the flue gas isolating valves.' },
        { id: '56.h', label: 'Observation of a test of the interlocking feature of soot blowers.' },
        { id: '56.i', label: 'Observation that the gas pressure regulating valve automatically closes when the inert gas blowers are secured.' },
        { id: '56.j', label: 'Checking, as far as practicable, the following alarms and safety devices of the inert gas system using simulated conditions where necessary : High oxygen content of gas in the inert gas main , Low gas pressure in the inert gas main,Low pressure in the supply to the deck water seal , High temperature of gas in the inert gas main , Low water pressure or low water-flow rate , Accuracy of portable and fixed oxygen-measuring equipment by means of calibration gas , High water level in the scrubber , Faile of the inert gas blowers , Failure of the power supply to the automatic control system for the gas regulating valve and to the instrumentation for continuous indication and permanent recording of pressure and oxygen content in the inert gas main and , High pressure of gas in the inert gas main.' },
        { id: '56.k', label: 'Upon completion of the above listed checks, has the proper operation of the inert aas system been checked?' },
        { id: '56.l', label: 'Has the deck water seal for the inert gas system been examined internally and found satisfactory?' },
        { id: '56.m', label: 'Has the condition of the non-return valve for the inert gas system been checked and found satisfactory?' },
        { id: '57', label: 'Examination of the fixed fire-fighting system for the cargo pump rooms and confirmation, as far as practicable and when appropriate, the operation of the remote means for closing the various openinQs' },
        { id: '58', label: 'Has the protection of cargo pump room, been checked? ; and in particular; have the following items; been examined and/or tested?  Temperature sensing devices for bulkhead shaft glands and alarms , Interlock between lighting and ventilation , Gas detection system and , Bilge level monitoring devices and alarms.' },
        { id: '59.0', label: 'Helicopter Facilities: Additional Requirements for helicopter facilities installed on or replaced on or after 01 July 2002:' },
        { id: '59.a', label: 'Were both the main and an emergency means of escape and access to thehelideck for fire-fighting and rescue personnel been examined and are they located as far apart from each other as possible?' },
        { id: '59.b', label: ' Do the drainage facilities for the helideck lead directly overboard and was it confirmed that the water does not fall onto any part of the ship after discharge?' },
        { id: '59.c', label: 'Following fire-fighting appliances and equipment were examined, tested as necessary and found in close proximity to the helideck: At least two dry powder extinguishers having a total capacity of not less than 45Kg; Carbon dioxide (CO2) extinguishers of a total capacity of not less than 18Kg or equivalent , A suitable foam application system , At least two nozzles of an approved dual-purpose type Uet/spray) and hoses to reach any part of the helideck , 	A fire-resistant blanket , A hook, for use as a grab or salving hook , A heavy-duty hacksaw, complete with at least 6 spare blades , A Ladder , A lift line that is 5 mm diameter and 15 m in length , Side-cutting pliers , Set of assorted screw drivers , A harness knife complete with sheath ' },
        { id: '59.d', label: 'Where the ship has helicopter refueling and hanger facilities, were the following items examined and tested as necessary? , The designated area for the storage of fuel tanks was isolated from areas containing a source of vapor ignition., The remote shutdown for the storage tank fuel pumps; was tested., "NO SMOKING" signs were displayed at appropriate locations., Are the enclosed hanger facilities or enclosed spaces containing refueling installations provided with mechanical ventilation ? ,Has the operations manual for each helicopter facility been siQhted on board ?' },
        { id: '60.0', label: 'NAVIGATION LIGHT:' },
        { id: '60.a', label: 'Navigation lights in good condition and operating satisfactorily' },
        { id: '60.b', label: 'Navigation light failure warning device: Visual / Audible on bridge operating efficiently' },
        { id: '60.c', label: 'Sidelight inboard screens painted matt black' },
        { id: '60.d', label: 'Set of spare bulbs available' },
    ];
    const initialSelections = {
        "59.d": "YES",
        "60.a": "NO",
        "60.b": "YES",
        "2": "YES",
        "1": "YES",

    }
    const [selections, setSelections] = useState({});

    useEffect(() => {
        setSelections(initialSelections);
    }, []);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setSelections((prevSelections) => ({
            ...prevSelections,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        const jsonOutput = JSON.stringify(selections, null, 2);
        console.log(jsonOutput);
        alert(`Selections: \n${jsonOutput}`);
    };
    const getLabel = (id) => {
        if (id.includes('.')) {
            const subpart = id.split('.')[1];
            if (subpart === '0') {
                return false;
            }
            return (
                <Box component="span" sx={{ ml: 2 }}>
                    {subpart}
                </Box>
            );
        }
        return id;
    };

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "87%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper">
                                        <div class="scroll-Dash">

                                            <div className="header-container">
                                                <h1><div className="header-surveyor">
                                                    Survey Checklist
                                                </div></h1>



                                            </div>

                                            <div>
                                                {checklistItems.map((item) => (
                                                    <FormControl component="fieldset" key={item.id} style={{ display: "flex" }}>
                                                        {getLabel(item.id) ?
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <div style={{ display: "flex", gap: "2%", width: "70%", alignItems: "baseline" }}>
                                                                    <h3 class="label-style">{getLabel(item.id)}</h3>
                                                                    <h3 class="label-style">{item.label}</h3>
                                                                </div>
                                                                <RadioGroup
                                                                    row
                                                                    aria-label={item.label}
                                                                    name={item.id}
                                                                    value={selections[item.id] || ''}
                                                                    onChange={handleChange}
                                                                >
                                                                    <FormControlLabel class="bullet-label" value="YES" control={<Radio />} label="Yes"
                                                                        sx={{
                                                                            '.MuiTypography-root': {
                                                                                fontSize: '10px !important',
                                                                            },
                                                                        }} />
                                                                    <FormControlLabel class="bullet-label" value="NO" control={<Radio />} label="No"
                                                                        sx={{
                                                                            '.MuiTypography-root': {
                                                                                fontSize: '10px !important',
                                                                            },
                                                                        }} />
                                                                    <FormControlLabel class="bullet-label" value="NA" control={<Radio />} label="NA"
                                                                        sx={{
                                                                            '.MuiTypography-root': {
                                                                                fontSize: '10px !important',
                                                                            },
                                                                        }} />
                                                                </RadioGroup>
                                                            </div>
                                                            :
                                                            <div style={{ display: "flex", gap: "1%", width: "100%", alignItems: "baseline" }}>
                                                                <h3 class="head-style">{item.id.split('.')[0]}</h3>
                                                                <h3 class="head-style">{item.label}</h3>
                                                            </div>
                                                        }

                                                    </FormControl>
                                                ))}
                                                <Button variant="contained" color="primary" onClick={handleSubmit}>
                                                    Submit
                                                </Button>
                                            </div>

                                        </div>
                                        <div class="btn-container-multiple">
                                            <button
                                                class="modal-close-btn" >
                                                Back
                                            </button>
                                            <button class="modal-preview-btn" >Next</button>


                                        </div>
                                    </div>

                                </div>

                            </div>

                        </main>
                    </TabPanel>
                </Tabs>
            </div>


        </div>

    );
}

export default SurveyChecklist;
